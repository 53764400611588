<pw-view-title>
  {{'general.infoPanels.booking.title' | translate}} {{bookingData?.booking.DATUM_BUCHUNG | date: 'shortDate'}}
</pw-view-title>

<pw-view-actions>
  <button mat-button [matMenuTriggerFor]="menu">
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item>{{'general.infoPanels.booking.menu.cancel' | translate}}</button>
    <button mat-menu-item>{{'general.infoPanels.booking.menu.delete' | translate}}</button>
    <button mat-menu-item>{{'general.infoPanels.booking.menu.edit' | translate}}</button>
    <button mat-menu-item>{{'general.infoPanels.booking.menu.shift' | translate}}</button>
    <button mat-menu-item>{{'general.infoPanels.booking.menu.changeTask' | translate}}</button>
  </mat-menu>
</pw-view-actions>

<div class="p4m-grid-3-col">

  <div>
    <mat-card *ngIf="bookingData?.booking">
      <mat-card-header>
        <mat-card-title>{{'general.infoPanels.booking.bookingInformation' | translate}}</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div>
          <mat-form-field style="width: 100%">
            <mat-datepicker #pickerDate></mat-datepicker>
            <input matInput [matDatepicker]="pickerDate" [(ngModel)]="bookingData.booking.DATUM_BUCHUNG"
                   [placeholder]="'general.infoPanels.booking.bookingDate' | translate"
                   readonly>
          </mat-form-field>
        </div>

        <div>
          <mat-form-field style="width: 100%">
            <mat-datepicker #pickerStart></mat-datepicker>
            <input matInput [matDatepicker]="pickerStart" [(ngModel)]="bookingData.booking.STARTBUCH"
                   [placeholder]="'general.infoPanels.booking.start' | translate"
                   readonly>
          </mat-form-field>
        </div>

        <div>
          <mat-form-field style="width: 100%">
            <mat-datepicker #pickerEnd></mat-datepicker>
            <input matInput [matDatepicker]="pickerEnd" [(ngModel)]="bookingData.booking.ENDEBUCH"
                   [placeholder]="'general.infoPanels.booking.end' | translate"
                   readonly>
          </mat-form-field>
        </div>

        <div>
          <mat-form-field style="width: 100%">
            <input matInput [placeholder]="'general.infoPanels.booking.bookingDate' | translate"
                   [(ngModel)]="bookingData.booking.STUNDEN" readonly>
          </mat-form-field>
        </div>

        <div>
          <mat-form-field style="width: 100%">
            <input matInput [placeholder]="'general.infoPanels.booking.feedback' | translate"
                   [(ngModel)]="bookingData.booking.RUECKMELDER_ID" readonly>
          </mat-form-field>
        </div>

        <div>
          <mat-form-field style="width: 100%">
            <input matInput [placeholder]="'general.infoPanels.booking.resource' | translate"
                   [(ngModel)]="bookingData.booking.CURRENT_RES_ID" readonly>
          </mat-form-field>
        </div>

      </mat-card-content>
    </mat-card>
  </div>

</div>
