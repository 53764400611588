import {Component} from '@angular/core';
import {DashboardItemComponent, DashboardService} from 'proleis-web-dashboard';

@Component({
  selector: 'p4m-logistics-actions-widget',
  templateUrl: 'logistics.actions.component.html'
})

export class LogisticsActionsComponent extends DashboardItemComponent {

  constructor(public ds: DashboardService) {
    super();
  }

  delete() {
    this.ds.removeDashboardItem(this.item.dashboardName, this.item);
  }
}
