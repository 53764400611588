<div gdColumns="auto" gdGap="24px">

  <pw-dashboard-item-options [item]="data.item"></pw-dashboard-item-options>

  <mat-form-field placeholder="bitte Maschine auswählen">
    <mat-select [(value)]="selectedMachine">
      <mat-option *ngFor="let machine of machines" [value]="machine">
        {{machine.NAME}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div style="justify-self: end">
    <button mat-button color="primary" (click)="dismiss()">
      ABBRECHEN
    </button>
    <button mat-raised-button color="primary" (click)="update()">
      ÜBERNEHMEN
    </button>
  </div>
</div>
