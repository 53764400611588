import {Component, OnInit} from '@angular/core';
import {Observable, of, switchMap} from 'rxjs';
import {CoreService, DocumentService, ProleisObject} from 'proleis-rest-client';
import {ActivatedRoute} from '@angular/router';
import {RedirectService} from '../../../services/redirect.service';
import {FileResponse} from '../../../../../../../../proleis-suppliers/src/app/models/file.response';
import {saveAs} from 'file-saver';

@Component({
  selector: 'app-proleis-for-mobile',
  templateUrl: './order.info.panel.component.html',
  styleUrls:['order.info.panel.component.scss']
})
export class OrderInfoPanelComponent implements OnInit {

  documents$: Observable<ProleisObject[]>;
  tools$: Observable<ProleisObject[]>;
  vcContainers$: Observable<ProleisObject[]>;
  order$: Observable<ProleisObject>;

  constructor(private cs: CoreService, private ds: DocumentService, private ar: ActivatedRoute, public rs: RedirectService) {
  }

  ngOnInit() {
    this.getOrder();
    this.getDocuments();
    this.getTools();
    this.getVcContainers();
  }

  getTools() {
    this.tools$ = this.cs.getChildren(this.ar.snapshot.params.orderId, '§c4489a82-295d-11b2-803c-b1a5d4697e33');
  }

  getVcContainers() {
    this.vcContainers$ = this.cs.getChildren(this.ar.snapshot.params.orderId, '§1233c2ba-28b1-11b2-80cc-032a5a7dee47');
  }

  getOrder() {
    const aliasFields = 'ANWENDUNG=ANWENDUNG.NAME;ART=ART_ID.NAME;AAASTATUS=AAA_STATUS_ID.NAME;DOKCONTAINER=OBJECT_ID.DOKUMENTCONTAINER_ID';
    this.order$ = this.cs.getObjectById<ProleisObject>(this.ar.snapshot.params.orderId, aliasFields);
  }

  getDocuments() {
    if (this.order$) {
      this.documents$ = this.order$.pipe(
        switchMap(order => {
          if (order.DOKCONTAINER) {
            return this.ds.getDocumentsInContainer(order.DOKCONTAINER);
          } else {
            return of([]);
          }
        })
      );
    }
  }

  downloadDocument(docId: string) {
    this.ds.getDocumentFile(docId).subscribe(response => {
      const fileResponse: FileResponse = FileResponse.parseResponse(response);
      saveAs(fileResponse.content, fileResponse.fileName);
    });
  }

}
