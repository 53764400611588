import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {ProleisForMobileState} from '../store.index';
import {CoreService, Param, ProleisObject, ScannerService, Types} from 'proleis-rest-client';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {LogisticsActions} from './logistics.index';
import {exhaustMap, map, switchMap, tap} from 'rxjs/operators';
import {RedirectService} from '../../general/services/redirect.service';
import {NotificationService} from 'proleis-web-app';
import {GUID_ACTION_LOGISTICS_RELOCATE} from "../../../p4m/guids";

@Injectable()
export class LogisticsEffects {

  constructor(private store: Store<ProleisForMobileState>, private cs: CoreService, private actions: Actions,
              private rs: RedirectService, private ns: NotificationService, private scannerService: ScannerService) {

  }

  scanObject$ = createEffect(
    () => {
      return this.actions.pipe(
        ofType(LogisticsActions.scanObject),
        exhaustMap(action => {
          const objectId = Number(action.scanCode);
          if (isNaN(objectId)) {
            return this.scannerService.executeScan(action.scanCode).pipe(
              map(result => LogisticsActions.onScanSequenceSuccess({result}))
            );
          } else {
            return this.cs.getObjectById<ProleisObject>(action.scanCode).pipe(
              map(scannedObject => LogisticsActions.onScanObjectSuccess({object: scannedObject}))
            );
          }
        })
      );
    }
  );

  onScanObjectSuccess$ = createEffect(
    () => {
      return this.actions.pipe(
        ofType(LogisticsActions.onScanObjectSuccess),
        tap(action => this.rs.redirectToInfoPanel(action.object.OBJECT_ID))
      );
    }, {
      dispatch: false
    }
  );

  relocate$ = createEffect(
    () => {
      return this.actions.pipe(
        ofType(LogisticsActions.relocate),
        exhaustMap(action => {
          return this.cs.getObjectByGuid<ProleisObject>(GUID_ACTION_LOGISTICS_RELOCATE).pipe(
            switchMap(relocateAction => {
              const params: Param[] = [];
              params.push({
                name: 'TARGET_ID',
                value: Number(action.targetId),
                type: Types.DOUBLE
              });
              return this.cs.executeAction(action.sourceId, relocateAction.OBJECT_ID, params, 'application/json').pipe(
                map(result => LogisticsActions.onRelocateSuccess())
              );
            })
          );
        })
      );
    });

  onRelocateSuccess$ = createEffect(
    () => {
      return this.actions.pipe(
        ofType(LogisticsActions.onRelocateSuccess),
        tap(action => this.ns.showNotification('Lagerinhalt umgebucht!'))
      );
    }, {dispatch: false}
  );

}
