<div class="p4m-flex-container">
  <h1 style="align-self: center">{{laneTitle}}</h1>

  <ng-container *ngIf="(tasks | async)?.length > 0; else noTasks">
    <div class="mat-elevation-z6" *ngFor="let task of (tasks | async)">
      <p4m-mobile-task-component [task]="task"></p4m-mobile-task-component>
      <mat-divider vertical="false"></mat-divider>
    </div>
  </ng-container>

  <ng-template #noTasks>
    <mat-card-content>
      {{'planning.board.noTasks' | translate}}
      <button mat-button color="primary" (click)="fireFilterEvent()">{{'planning.board.changeFilter' | translate}}</button>
    </mat-card-content>
  </ng-template>
</div>
