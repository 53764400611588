import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ChartColorService {

  currentColorIndex = 0;
  colors: string[] = ['#f44336', '#e91e63', '#e91e63', '#673ab7', '#3f51b5', '#2196f3', '#03a9f4', '#00bcd4', '#009688',
    '#4caf50', '#8bc34a', '#cddc39', '#ffeb3b', '#ffc107', '#ff9800', '#ff5722', '#795548', '#9e9e9e', '#607d8b'];
  mappedColors: Map<string, string>;

  constructor() {
    this.mappedColors = new Map();
    const loadedColors = localStorage.getItem('p4m.chart.colors');
    if (loadedColors) {
      this.mappedColors = new Map(JSON.parse(loadedColors));
    }
    this.colors.sort((a, b) => 0.5 - Math.random());
  }

  getColors() {
    return this.colors;
  }

  getColorForId(id: string) {
    if (this.mappedColors instanceof Map && this.mappedColors.has(id)) {
      return this.mappedColors.get(id);
    } else {
      this.mappedColors.set(id, this.colors[this.currentColorIndex]);
      this.currentColorIndex++;
      localStorage.setItem('p4m.chart.colors', JSON.stringify([...this.mappedColors]));
      return this.mappedColors.get(id);
    }
  }

}
