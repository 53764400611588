import {Component, OnInit} from '@angular/core';
import {DashboardItemComponent, DashboardService} from 'proleis-web-dashboard';
import {Store} from '@ngrx/store';
import {ProleisForMobileState} from '../../../../store/store.index';
import {DashboardActions} from '../../../../store/dashboard/dashboard.index';
import {Observable} from 'rxjs';
import {DeliveryInfo} from '../../../../store/dashboard/dashboard.state';
import {selectDashboardDeliveryInfo} from '../../../../store/dashboard/dashboard.selectors';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {DashboardDeliveryInfoOptionsComponent} from './dashboard.delivery.info.options';
import {tap} from 'rxjs/operators';

@Component({
  selector: 'p4m-dashboard-delivery-info',
  templateUrl: 'dashboard.delivery.info.component.html'
})
export class DashboardDeliveryInfoComponent extends DashboardItemComponent implements OnInit {

  deliveryInfo$: Observable<DeliveryInfo>;

  constructor(private store: Store<ProleisForMobileState>, public ds: DashboardService,
              private bottomSheet: MatBottomSheet) {
    super();
  }

  ngOnInit(): void {
    this.store.dispatch(DashboardActions.loadDeliveryState({
      id: this.item.options.pseId
    }));

    this.deliveryInfo$ = this.store.select(selectDashboardDeliveryInfo, this.item.options.pseId);
  }

  openOptionsBottomSheet() {
    this.bottomSheet.open(DashboardDeliveryInfoOptionsComponent, {
      data: {
        item: this.item,
        dashboard: this.item.dashboardName
      }
    });
  }

  delete() {
    this.ds.removeDashboardItem(this.item.dashboardName, this.item);
  }

}
