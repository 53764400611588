import {AppConfigService, OidcAuth, PAUTH_TOKEN, TokenType} from 'proleis-web-app';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {exhaustMap, Observable, of} from 'rxjs';
import {Injectable} from '@angular/core';
import {shareReplay} from 'rxjs/operators';

@Injectable()
export class OidcAuthMethod extends OidcAuth {

  constructor(private oidcSecurityService: OidcSecurityService, private configService: AppConfigService) {
    super();
  }

  login(credentials: any): Observable<boolean> {
    this.oidcSecurityService.authorize();
    return of(true);
  }

  logout(): Observable<boolean> {
    this.oidcSecurityService.logoff().subscribe();
    localStorage.removeItem(PAUTH_TOKEN);
    return of(true);
  }

  validateAuth(): Observable<boolean> {
    return this.oidcSecurityService.checkAuth().pipe(
      exhaustMap(loginResponse => {
        if (loginResponse.isAuthenticated) {
          this.getToken().subscribe(token => {
            localStorage.setItem(PAUTH_TOKEN, token);
          });
        }
        return this.oidcSecurityService.isAuthenticated();
      }),
      shareReplay(1)
    );
  }

  getToken(): Observable<string> {
    const oidcConfig: any = this.configService.getConfig().loginConfig.loginTypes.filter(config => config.loginType === 'OIDC')[0];
    if (oidcConfig) {
      switch (oidcConfig.tokenType) {
        default:
          return this.oidcSecurityService.getIdToken();
        case TokenType.idToken:
          return this.oidcSecurityService.getIdToken();
        case TokenType.accessToken:
          return this.oidcSecurityService.getAccessToken();
      }
    }
  }
}
