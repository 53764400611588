import {PlanningState, ppsResourceAdapter} from './planning.state';
import {createReducer, on} from '@ngrx/store';
import {PlanningActions} from './planning.index';

const initialState: PlanningState = {
  ppsResources: [],
  ppsState: ppsResourceAdapter.getInitialState(),
  ppsFilter: null,
  evaluationTypes: [],
  userBookings: [],
  activeBookings: [],
  bookingMetadata: null
};

const reducer = createReducer(
  initialState,
  on(PlanningActions.loadPpsResources, state => state),
  on(PlanningActions.onPpsResourcesLoaded, (state, action) => {
    return {
      ...state,
      ppsResources: action.resources
    };
  }),
  on(PlanningActions.loadPpsResourceInfoState, state => state),
  on(PlanningActions.onPpsResourceInfoStateLoaded, (state, action) => {
    return {...state, ppsState: ppsResourceAdapter.upsertOne(action.resourceInfo, state.ppsState)};
  }),
  on(PlanningActions.setTaskInProgress, state => state),
  on(PlanningActions.onSetTaskInProgress, state => state),
  on(PlanningActions.setTaskInterrupted, state => state),
  on(PlanningActions.onSetTaskInterrupted, state => state),
  on(PlanningActions.setTaskComplete, state => state),
  on(PlanningActions.onSetTaskComplete, state => state),
  on(PlanningActions.setPpsFilter, state => state),
  on(PlanningActions.onPpsFilterSet, state => state),
  on(PlanningActions.loadPpsFilter, state => state),
  on(PlanningActions.onPpsFilterLoaded, (state, action) => {
    return {
      ...state,
      ppsFilter: action
    };
  }),
  on(PlanningActions.loadEvaluationTypes, state => state),
  on(PlanningActions.onEvaluationTypesLoaded, (state, action) => {
    return {
      ...state,
      evaluationTypes: action.evaluationTypes
    };
  }),
  on(PlanningActions.loadUserBookings, state => state),
  on(PlanningActions.onUserBookingsLoaded, (state, action) => {
    return {
      ...state,
      userBookings: action.bookings
    };
  }),
  on(PlanningActions.loadBookingMetaData, state => state),
  on(PlanningActions.onBookingMetaDataLoaded, (state, action) => {
    return {
      ...state,
      bookingMetadata: action.metadata
    };
  }),
  on(PlanningActions.sortBookings, state => state),
  on(PlanningActions.onBookingsSorted, (state, action) => {
    return {
      ...state,
      userBookings: action.bookings
    };
  }),
  on(PlanningActions.startTimeTracking, state => state),
  on(PlanningActions.onTimeTrackingStarted, state => state),
  on(PlanningActions.stopTimeTracking, state => state),
  on(PlanningActions.onTimeTrackingStopped, state => state),
  on(PlanningActions.loadActiveBooking, state => state),
  on(PlanningActions.onActiveBookingLoaded, (state, action) => {
    return {
      ...state,
      activeBookings: action.activeBookings
    };
  })
);

export function planningReducer(state: PlanningState, action) {
  return reducer(state, action);
}
