import {Component, EventEmitter, Input, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {ProleisObject} from 'proleis-rest-client/lib/models/proleis-object-models/proleis.object';
import {MatMenuTrigger} from '@angular/material/menu';
import {Store} from '@ngrx/store';
import {ProleisForMobileState} from '../../../../store/store.index';
import {ConfirmDialogComponent} from '../../../../general/components/confirm-dialog/confirm-dialog.component';
import {NotificationService} from 'proleis-web-app';
import {MatDialog} from '@angular/material/dialog';
import {CoreService, RequestParam} from 'proleis-rest-client';
import {RedirectService} from '../../../../general/services/redirect.service';

@Component({
  selector: 'p4m-booking-component',
  templateUrl: 'booking.component.html',
  styleUrls: ['booking.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BookingComponent {

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  @Output()
  bookingChanged: EventEmitter<number> = new EventEmitter<number>();

  @Input()
  booking: ProleisObject;

  constructor(private store: Store<ProleisForMobileState>, private ns: NotificationService, private dialog: MatDialog,
              private cs: CoreService, private rs: RedirectService) {
  }

  getContainerClass() {
    if (this.booking?.IST_MANNLOS) {
      return 'machine-booking';
    } else {
      return 'user-booking';
    }
  }

  isNotEmpty(value: string) {
    if (value) {
      return value.length > 0;
    } else {
      return false;
    }
  }

  storno() {
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Buchung stornieren',
        message: 'Möchten Sie die Buchung tatsächlich stornieren?'
      }
    }).afterClosed().subscribe(result => {
      if (result) {
        const params: RequestParam = {
          BOOKING_ID: this.booking.BDE_BUCHUNG_ID
        };
        this.cs.executeAction(this.booking.OBJECT_ID, '§b18025f6-2b5f-11b2-80e8-0715a3154876', params).subscribe(storno => {
          this.ns.showNotification('Buchung storniert');
          this.bookingChanged.emit(this.booking.OBJECT_ID);
        });
      }
    });
  }

  redirect(id: string) {
    this.rs.redirectToInfoPanel(id);
  }

}
