import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeViewComponent} from './home.view.component';
import {AuthGuard} from './p4m/auth.guard';
import {ErrorPageComponent} from './p4m/error.page.component';
import {UserInfoPanelComponent} from './modules/general/components/info-panels/user/user.info.panel.component';
import {AppComponent} from './app.component';

const routes: Routes = [
  {path: 'app', component: AppComponent},
  {path: 'home', component: HomeViewComponent},
  {path: '', pathMatch: 'full', redirectTo: 'home'},
  {path: 'redirect', redirectTo: 'home'},
  {
    path: 'general',
    loadChildren: () => import('./modules/general/general.module').then(m => m.GeneralModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'general/info-panels/current-user',
    component: UserInfoPanelComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'logistics',
    loadChildren: () => import('./modules/logistics/logistics.module').then(m => m.LogisticsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'quality',
    loadChildren: () => import('./modules/quality/quality.module').then(m => m.QualityModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'planning',
    loadChildren: () => import('./modules/planning/planning.module').then(m => m.PlanningModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'fpe',
    loadChildren: () => import('./modules/fpe/fpe.module').then(m => m.FpeModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'error',
    component: ErrorPageComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
