import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {ProleisForMobileState} from '../../../../store/store.index';
import {ActivatedRoute} from '@angular/router';
import {GeneralActions} from '../../../../store/general/general.index';
import {selectMs} from '../../../../store/general/general.selectors';
import {Observable} from 'rxjs';
import {Milestone} from 'proleis-rest-client';

@Component({
  selector: 'p4m-ms-info',
  templateUrl: 'ms.info.panel.component.html'
})
export class MsInfoPanelComponent implements OnInit {

  ms$: Observable<Milestone>;

  constructor(private activatedRoute: ActivatedRoute, private store: Store<ProleisForMobileState>) {

  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.store.dispatch(GeneralActions.onLoadMs({msId: params.id}));
      this.ms$ = this.store.select(selectMs);
    });
  }

}
