import {Component, OnInit, ViewChild} from '@angular/core';
import {CoreService, PlanningService, ProleisObject, Task, UserService} from 'proleis-rest-client';
import {ActivatedRoute} from '@angular/router';
import {combineLatest, debounceTime, exhaustMap, iif, map, Observable, of, shareReplay, startWith, tap} from 'rxjs';
import {BreakpointService, LoadingService, NotificationService} from 'proleis-web-app';
import {MatSlideToggle} from '@angular/material/slide-toggle';
import {FormBuilder, FormControl} from '@angular/forms';

@Component({
  selector: 'p4m-view-tasks',
  templateUrl: 'view-tasks.component.html',
  styleUrls: ['view-tasks.component.scss']
})
export class ViewTasksComponent implements OnInit {

  onlyAssignedTasks: FormControl = new FormControl<boolean>(true);
  searchControl: FormControl = new FormControl<string>('');
  part$: Observable<ProleisObject>;
  tasks$: Observable<Task[]>;
  isPhone$: Observable<boolean>;

  @ViewChild('assignedTasksToggle') assignedToggle: MatSlideToggle;

  currentBooking$: Observable<ProleisObject[]>;

  constructor(private coreService: CoreService, private activatedRoute: ActivatedRoute, private userService: UserService,
              private formBuilder: FormBuilder, private bs: BreakpointService, private ns: NotificationService,
              public ls: LoadingService, private ps: PlanningService) {
  }

  ngOnInit() {
    this.getCurrentBooking();
    this.part$ = this.coreService.getObjectById(this.activatedRoute.snapshot.params.partId, this.getPartAliasFields());
    this.tasks$ = this.getTasks();
    this.isPhone$ = this.bs.isSmall();

    this.onlyAssignedTasks.valueChanges.pipe(
      shareReplay(1),
      debounceTime(250),
      tap(value => this.tasks$ = this.getTasks())
    ).subscribe();

  }

  getTaskAliasFields() {
    return 'AFOARTNAME=AFO_ART_ID.NAME;ORDERNAME=PARENT.AUFTRAG_ID.TEXT;POSNAME=LINK_ID.TEXT;' +
      'PARTNAME=LINK_ID.PARENT[TYP_ID.GUID=b514dea4-27fa-11b2-800a-b390cd5bbe84].TEXT;' +
      'PROJECTNAME=LINK_ID.PARENT[TYP_ID.GUID=b514d7ec-27fa-11b2-800a-b390cd5bbe84].TEXT' +
      ';LINKROOT=LINK_ID.ROOT_ID;LINKHEAD=LINK_ID.HEAD_ID;LINKNAME=LINK_ID.TEXT;ROOTNAME=LINK_ID.ROOT_ID.TEXT;INFOTEXT=OBJECT_ID.INFOTEXT';
  }

  getPartAliasFields() {
    return 'PARENTNAME=PARENT.TEXT;TYPGUID=TYP_ID.GUID;';
  }

  startBooking(task: Task) {
    this.userService.startTimeTracking(task.OBJECT_ID).subscribe(result => {
      this.ns.showNotification('Buchung gestartet');
      this.getTasks();
      this.getCurrentBooking();
    });
  }

  getTasks(): Observable<Task[]> {
    const filter$ = this.searchControl.valueChanges.pipe(
      startWith(''),
      debounceTime(250)
    )
    return this.part$.pipe(
      exhaustMap(part => {
        return combineLatest([filter$, this.ps.getTasks(part.OBJECT_ID, this.onlyAssignedTasks.value, this.getTaskAliasFields())]).pipe(
          map(([filter, tasks]) => {
            return this.filterTasks(tasks, filter);
          })
        );
      })
    ).pipe(
      map(tasks => {
        return this.sortTasks(tasks);
      })
    );
  }

  filterTasks(tasks: Task[], filter: string): Task[] {
    return tasks.filter(t => t.NAME.toLowerCase().includes(filter.toLowerCase()));
  }

  sortTasks(tasks: Task[]): Task[] {
    return tasks.sort((a, b) => {
      if (a.STARTPLAN > b.STARTPLAN) {
        return -1;
      }
      if (a.STARTPLAN < b.STARTPLAN) {
        return 1;
      }
      return 0;
    });
  }

  getCurrentBooking() {
    const aliasFields = 'AFONAME=AFO_ID.TEXT;TOOLNAME=BEMI_ID.TEXT;RESNAME=RES_ID.TEXT';
    this.currentBooking$ = this.userService.getCurrentBookings(aliasFields);
  }

  onTaskUpdated() {
    this.tasks$ = this.getTasks();
  }
}
