<div class="p4m-flex-container">

  <pw-dashboard-item-options [item]="data.item"></pw-dashboard-item-options>

  <pw-search [allowedTypes]="allowedTypes" [minCharacters]="2" [debounceTime]="250" [multiple]="false"
             (selectionChanged)="onSelectionChanged($event)"></pw-search>

  <div style="justify-self: end">
    <button mat-button color="primary" (click)="dismiss()">
      {{'dashboard.widgets.deliveryInfo.options.discard' | translate}}
    </button>
    <button mat-raised-button color="primary" (click)="update()">
      {{'dashboard.widgets.deliveryInfo.options.save' | translate}}
    </button>
  </div>
</div>
