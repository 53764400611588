<div class="p4m-flex-container" style="justify-content: center">
  <h3>Datumsbereich für Filter auswählen</h3>

  <mat-form-field style="width: 100%;">
    <mat-label>Start</mat-label>
    <input matInput [matDatepicker]="pickerStart" [(ngModel)]="start">
    <mat-datepicker-toggle matIconSuffix [for]="pickerStart"></mat-datepicker-toggle>
    <mat-datepicker #pickerStart></mat-datepicker>
  </mat-form-field>

  <mat-form-field style="width: 100%;">
    <mat-label>Ende</mat-label>
    <input matInput [matDatepicker]="pickerEnd" [(ngModel)]="end">
    <mat-datepicker-toggle matIconSuffix [for]="pickerEnd"></mat-datepicker-toggle>
    <mat-datepicker #pickerEnd></mat-datepicker>
  </mat-form-field>

  <div style="align-items: end">
    <button mat-button (click)="dismiss()">VERWERFEN</button>
    <button mat-button (click)="save()">SPEICHERN</button>
  </div>
</div>

