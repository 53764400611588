import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {ProleisForMobileState} from '../store.index';
import {AnalyticsService, CoreService, MachineService, Param, ProleisObject, Task, Types, UserService} from 'proleis-rest-client';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {DashboardActions} from './dashboard.index';
import {concatMap, exhaustMap, map, tap} from 'rxjs/operators';
import {forkJoin} from 'rxjs';
import {CurrentViewService} from 'proleis-web-app';
import {GUID_PATH_PLAN} from "../../../p4m/guids";

@Injectable()
export class DashboardEffects {

  constructor(private store: Store<ProleisForMobileState>, private actions: Actions, private ms: MachineService,
              private cs: CoreService, private us: UserService, private as: AnalyticsService, private cvs: CurrentViewService) {

  }

  initMdeInfo$ = createEffect(
    () => {
      return this.actions.pipe(
        ofType(DashboardActions.loadeMdeData),
        concatMap(action => {
          this.cvs.setLoading(true);
          return forkJoin([
              this.ms.getMachineStatistics(action.machineId, true, true),
              this.cs.getObjectById<ProleisObject>(action.machineId)
            ]
          ).pipe(
            map(([machineData, machine]) => {
              return DashboardActions.onMdeDataLoaded({
                mdeInfo: {
                  machine,
                  machineData
                }
              });
            })
          );
        })
      );
    }
  );

  onMdeDataLoaded$ = createEffect(() => {
    return this.actions.pipe(
      ofType(DashboardActions.onMdeDataLoaded),
      tap(action => this.cvs.setLoading(false))
    );
  }, {dispatch: false});

  loadFavorites$ = createEffect(
    () => {
      return this.actions.pipe(
        ofType(DashboardActions.loadFavorites),
        concatMap(action => {
          this.cvs.setLoading(true);
          return this.us.getUserFavorites().pipe(
            map(favorites => DashboardActions.onFavoritesLoaded({
              favorites
            }))
          );
        })
      );
    }
  );

  onFavoritesLoaded$ = createEffect(() => {
    return this.actions.pipe(
      ofType(DashboardActions.onFavoritesLoaded),
      tap(action => this.cvs.setLoading(false))
    );
  }, {dispatch: false});

  loadUserResources$ = createEffect(
    () => {
      return this.actions.pipe(
        ofType(DashboardActions.loadUserResources),
        exhaustMap(action => {
          this.cvs.setLoading(true);
          return this.us.getUserResources().pipe(
            map(resources => DashboardActions.onUserResourcesLoaded({resources}))
          );
        })
      );
    }
  );

  onUserResourcesLoaded$ = createEffect(() => {
    return this.actions.pipe(
      ofType(DashboardActions.onUserResourcesLoaded),
      tap(action => this.cvs.setLoading(false))
    );
  }, {dispatch: false});

  loadTasks$ = createEffect(
    () => {
      return this.actions.pipe(
        ofType(DashboardActions.loadTasks),
        exhaustMap(action => {
            this.cvs.setLoading(true);
            const options$ = forkJoin([
              this.cs.getObjectByGuid<ProleisObject>(GUID_PATH_PLAN),
              this.cs.getObjectById<ProleisObject>(action.resId)
            ]);
            return options$.pipe(
              exhaustMap(([pathPlan, res]) => {
                return this.cs.getChildren<Task[]>(res.OBJECT_ID, pathPlan.OBJECT_ID).pipe(
                  map(tasks => DashboardActions.onTasksLoaded({tasks}))
                );
              })
            );
          }
        )
      );
    });

  onTasksLoaded$ = createEffect(() => {
    return this.actions.pipe(
      ofType(DashboardActions.onTasksLoaded),
      tap(action => this.cvs.setLoading(false))
    );
  }, {dispatch: false});

  loadCapacityHeatmap$ = createEffect(
    () => {
      return this.actions.pipe(
        ofType(DashboardActions.loadCapacityHeatmap),
        concatMap(action => {
          this.cvs.setLoading(true);
          const params: Param[] = [
            {
              name: 'GROUPVIEWDATA_TASKS_LINK.QUERY_PARENT_ID',
              value: action.queryParentId,
              type: Types.DOUBLE
            },
            {
              name: 'TASK_PLANNING_SQL.FT_START_DATE',
              value: action.startDate,
              type: Types.DATE
            },
            {
              name: 'TASK_PLANNING_SQL.FT_END_DATE',
              value: action.endDate,
              type: Types.DATE
            },
            {
              name: 'TASK_PLANNING_SQL.FT_RES',
              value: action.filterIds,
              type: Types.TEXT
            },
            {
              name: 'TASK_PLANNING_SQL.FT_INCLUDE_PR',
              value: action.includePR,
              type: Types.BOOL
            },
            {
              name: 'TASK_PLANNING_SQL.FT_INCLUDE_VP',
              value: action.includeVP,
              type: Types.BOOL
            },
            {
              name: 'TASK_PLANNING_SQL.FT_ONLY_CAPA_ACTIVE_RES',
              value: action.onlyCapacityActive,
              type: Types.BOOL
            },
            {
              name: 'TASK_PLANNING_SQL.FT_EXCLUDE_FINISHED',
              value: action.excludeFinished,
              type: Types.BOOL
            },
            {
              name: 'TASK_TIME_DISTRIBUTION_WORK.TIME_UNIT',
              value: action.timeUnit,
              type: Types.DOUBLE
            },
            {
              name: 'TASK_TIME_DISTRIBUTION_WORK.GROUPFIELD',
              value: action.groupField,
              type: Types.TEXT
            },
            {
              name: 'TASK_TIME_DISTRIBUTION_WORK.START_DATE',
              value: action.startDate,
              type: Types.DATE
            },
            {
              name: 'TASK_TIME_DISTRIBUTION_WORK.END_DATE',
              value: action.endDate,
              type: Types.DATE
            },
            {
              name: 'TASK_TIME_DISTRIBUTION_WORK.CALENDAR_ID',
              value: action.calendarId,
              type: Types.DOUBLE
            }
          ];
          return this.as.executeReport('TASK_WORK_TIMELABEL_GROUPED', params).pipe(
            map(result => DashboardActions.onCapacityHeatmapLoaded({heatmapData: result}))
          );
        })
      );
    }
  );

  onCapacityHeatmapLoaded$ = createEffect(() => {
    return this.actions.pipe(
      ofType(DashboardActions.onCapacityHeatmapLoaded),
      tap(action => this.cvs.setLoading(false))
    );
  }, {dispatch: false});

  loadIncidentInfo$ = createEffect(
    () => {
      return this.actions.pipe(
        ofType(DashboardActions.loadIncidentInfo),
        concatMap(action => {
          this.cvs.setLoading(true);
          const params: Param[] = [
            {
              name: 'SQL_ISSUES.FT_START_DATE',
              value: action.startDate,
              type: Types.DATE
            },
            {
              name: 'SQL_ISSUES.FT_END_DATE',
              value: action.endDate,
              type: Types.DATE
            },
            {
              name: 'GROUPVIEWDATA_ISSUES_LINK.QUERY_PARENT_ID',
              value: 241,
              type: Types.DOUBLE
            },
            {
              name: 'GROUPBY_FIELD.DATAELEMENT',
              value: action.groupBy,
              type: Types.TEXT
            },
            {
              name: 'EXPRESSION_GROUP_LABEL.EXPRESSION',
              value: 'HAUPTKATEGORIE',
              type: Types.TEXT
            }
          ];
          return this.as.executeReport('ISSUES_TIMELABEL_GROUPED', params).pipe(
            map(incidents => DashboardActions.onIncidentInfoLoaded({incidents}))
          );
        })
      );
    }
  );

  onIncidentsLoaded$ = createEffect(() => {
    return this.actions.pipe(
      ofType(DashboardActions.onIncidentInfoLoaded),
      tap(action => this.cvs.setLoading(false))
    );
  }, {dispatch: false});

  loadDeliveryInfo$ = createEffect(() => {
    return this.actions.pipe(
      ofType(DashboardActions.loadDeliveryState),
      concatMap(action => {
        this.cvs.setLoading(true);
        const aliasFields = 'COUNT_ALL=OBJECT_ID.CALC_PSETREE_CNT_ALL;' +
          'COUNT_REQUESTED=OBJECT_ID.CALC_PSETREE_CNT_REQUESTED;' +
          'COUNT_ORDERED=OBJECT_ID.CALC_PSETREE_CNT_ORDERED;' +
          'COUNT_DELIVERED=OBJECT_ID.CALC_PSETREE_CNT_DELIVERED;' +
          'COUNT_ASSEMBLY_READY=OBJECT_ID.CALC_PSETREE_CNT_ASSEMBLY_READY;' +
          'PERCENT_ORDERED=OBJECT_ID.CALC_PSETREE_PERCENT_ORDERED;' +
          'PERCENT_DELIVERED=OBJECT_ID.CALC_PSETREE_PERCENT_DELIVERED;' +
          'PERCENT_ASSEMBLY_READY=OBJECT_ID.CALC_PSETREE_PERCENT_ASSEMBLY_READY';
        return this.cs.getObjectById<ProleisObject>(action.id, aliasFields).pipe(
          map(result => DashboardActions.onDeliveryStateLoaded({
            deliveryInfo: {
              countAll: result.COUNT_ALL,
              countAssemblyReady: result.COUNT_ASSEMBLY_READY,
              countDelivered: result.COUNT_DELIVERED,
              countOrdered: result.COUNT_ORDERED,
              countRequested: result.COUNT_REQUESTED,
              percentAssemblyReady: result.PERCENT_ASSEMBLY_READY,
              percentDelivered: result.PERCENT_DELIVERED,
              percentOrdered: result.PERCENT_ORDERED,
              pse: result
            }
          }))
        );
      })
    );
  });

  onDeliveryStateLoaded$ = createEffect(() => {
    return this.actions.pipe(
      ofType(DashboardActions.onDeliveryStateLoaded),
      tap(action => this.cvs.setLoading(false))
    );
  }, {dispatch: false});

  loadCapacityWorkload$ = createEffect(() => {
    return this.actions.pipe(
      ofType(DashboardActions.loadCapacityWorkload),
      concatMap(action => {
        const params: Param[] = [
          {
            name: 'RES_IDS',
            type: Types.TEXT,
            value: action.resIds
          },
          {
            name: 'START',
            type: Types.DATE,
            value: action.start
          },
          {
            name: 'END',
            type: Types.DATE,
            value: action.end
          },
          {
            name: 'GROUPFIELD',
            type: Types.TEXT,
            value: action.groupField
          },
          {
            name: 'TIMEUNIT',
            type: Types.DOUBLE,
            value: action.timeUnit
          }
        ];
        return this.as.executeReport('P4M_CAPACITY_GROUPED', params).pipe(
          map(capacityData => DashboardActions.onCapacityWorkloadLoaded({
            capacityData: {
              res: action.resIds,
              capacityData
            }
          }))
        );
      })
    );
  });

  onCapacityWorkloadLoaded$ = createEffect(() => {
    return this.actions.pipe(
      ofType(DashboardActions.onCapacityWorkloadLoaded)
    );
  }, {dispatch: false});

}
