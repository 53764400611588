import {createAction, props} from '@ngrx/store';
import {CreateTimetrackingBody, Resource, Task} from 'proleis-rest-client';
import {BookingMetadata, PpsFilter, PpsResource} from './planning.state';
import {ProleisObject} from 'proleis-rest-client/lib/models/proleis-object-models/proleis.object';

export const loadPpsResources = createAction(
  '[PPS] load resources'
);

export const onPpsResourcesLoaded = createAction(
  '[PPS] resources loaded',
  props<{ resources: Resource[] }>()
);

export const loadPpsResourceInfoState = createAction(
  '[PPS] load pps resource info',
  props<{ resId: string }>()
);

export const onPpsResourceInfoStateLoaded = createAction(
  '[PPS] pps resource info loaded',
  props<{ resourceInfo: PpsResource }>()
);

export const loadPpsFilter = createAction(
  '[PPS] load filter'
);

export const onPpsFilterLoaded = createAction(
  '[PPS] filter settings loaded',
  props<PpsFilter>()
);

export const setPpsFilter = createAction(
  '[PPS] set filter',
  props<{
    start: Date,
    end: Date,
    includeFinished: boolean,
    onlyReleased: boolean,
    lead: number,
    resId?: string
  }>()
);

export const onPpsFilterSet = createAction(
  '[PPS] filter set'
);

export const setTaskInterrupted = createAction(
  '[Planning] set task interrupted',
  props<{ taskId: string, resId: string }>()
)

export const onSetTaskInterrupted = createAction(
  '[Planning] on task set interrupted',
  props<{resId: string}>()
);

export const setTaskInProgress = createAction(
  '[Planning] set task in progress',
  props<{ taskId: string, resId: string }>()
);

export const onSetTaskInProgress = createAction(
  '[Planning] on task set in progress',
  props<{resId: string}>()
);

export const setTaskComplete = createAction(
  '[Planning] set task complete',
  props<{ taskId: string, resId: string }>()
);

export const onSetTaskComplete = createAction(
  '[Planning] on task set completed',
  props<{resId: string}>()
);

export const createTimeTracking = createAction(
  '[Planning] create time tracking',
  props<{
    body: CreateTimetrackingBody,
    startObjectId?: string
  }>()
);

export const onTimeTrackingCreated = createAction(
  '[Planning] on time tracking created',
  props<{ result: any }>()
);

export const loadEvaluationTypes = createAction(
  '[Planning] load evaluation types'
);

export const onEvaluationTypesLoaded = createAction(
  '[Planning] on evaluation types loaded',
  props<{ evaluationTypes: ProleisObject[] }>()
);

export const loadUserBookings = createAction(
  '[Planning] load user bookings',
  props<{ position?: string; start?: Date; end?: Date; aliasFields?: string; fields?: string }>()
);

export const onUserBookingsLoaded = createAction(
  '[Planning] user bookings loaded',
  props<{ bookings: ProleisObject[] }>()
);

export const loadBookingMetaData = createAction(
  '[Planning] load booking metadata',
  props<{ bookingId: string, objectId: string }>()
);

export const onBookingMetaDataLoaded = createAction(
  '[Planning] booking metadata loaded',
  props<{ metadata: BookingMetadata }>()
);

export const sortBookings = createAction(
  '[Planning] sort bookings',
  props<{ order: string }>()
);

export const onBookingsSorted = createAction(
  '[Planning] on bookings sorted',
  props<{ bookings: ProleisObject[] }>()
);

export const startTimeTracking = createAction(
  '[Planning] starting time tracking',
  props<{ taskId: string }>()
);

export const onTimeTrackingStarted = createAction('[Planning] time tracking started');

export const stopTimeTracking = createAction(
  '[Planning] stopping time tracking',
  props<{ taskId: string }>()
);

export const onTimeTrackingStopped = createAction('[Planning] stopped time tracking');

export const loadActiveBooking = createAction('[Planning] loading active booking');

export const onActiveBookingLoaded = createAction(
  '[Planning] active booking loaded',
  props<{ activeBookings: ProleisObject[] }>()
);
