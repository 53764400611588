import {createReducer, on} from '@ngrx/store';
import {setLoading, toggleSideDrawer} from './ui.actions';
import {UiState} from './ui.state';

export const uiFeatureKey = 'ui';

export const initialState: UiState = {
  sideDrawerOpened: false,
  isLoading: false
};

const uiReducer = createReducer(
  initialState,
  on(
    toggleSideDrawer,
    state => (state)
  ),
  on(
    setLoading,
    (state, action) => ({...state, isLoading: action.isLoading})
  )
);

export function reducer(state: UiState, action) {
  return uiReducer(state, action);
}
