<mat-card>
  <mat-card-title>{{'dashboard.widgets.logistics.title' | translate}}</mat-card-title>
  <mat-card-content>
    <mat-nav-list>
      <mat-list-item routerLink="/logistics/scanner">
        <mat-icon mat-list-icon>info</mat-icon>
        {{'dashboard.widgets.logistics.scanner' | translate}}
      </mat-list-item>
      <mat-list-item routerLink="/logistics/relocation">
        <mat-icon mat-list-icon>import_export</mat-icon>
        {{'dashboard.widgets.logistics.rebookStockContent' | translate}}
      </mat-list-item>
      <mat-list-item routerLink="/logistics/update">
        <mat-icon mat-list-icon>sync_alt</mat-icon>
        {{'dashboard.widgets.logistics.rebookPositions' | translate}}
      </mat-list-item>
      <mat-list-item routerLink="/logistics/create-transport">
        <mat-icon mat-list-icon>local_shipping</mat-icon>
        {{'dashboard.widgets.logistics.createTransport' | translate}}
      </mat-list-item>
    </mat-nav-list>
  </mat-card-content>
  <mat-card-actions align="end">
    <button mat-button color="primary" *ngIf="ds.isDashboardEditable() | async" (click)="delete()">
      <mat-icon>remove</mat-icon>
    </button>
  </mat-card-actions>
</mat-card>
