<pw-view-title>Vorfall anlegen</pw-view-title>

<div class="incident-grid mw-70">
  <div style="font-weight: bold; grid-column: auto / span 2">{{ (referenceObject$ | async)?.name }}</div>

  <mat-form-field style="grid-column: auto / span 2">
    <mat-label>Zusammenfassung</mat-label>
    <input matInput type="text" [(ngModel)]="name">
  </mat-form-field>

  <mat-form-field appearance="fill" style="grid-column: auto / span 2">
    <mat-label>Priorität</mat-label>
    <mat-select [(value)]="selectedPrio">
      <mat-option *ngFor="let prio of priorities$ | async" [value]="prio">
        {{ prio.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Hauptkategorie</mat-label>
    <mat-select (selectionChange)="onCategoryChanged($event)" [(value)]="selectedCategory">
      <mat-option *ngFor="let category of categories$ | async" [value]="category">
        {{ category.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Kategorie</mat-label>
    <mat-select [(value)]="selectedSubcategory">
      <mat-option *ngFor="let category of subcategories$ | async" [value]="category">
        {{ category.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Verursacher (intern)</mat-label>
    <mat-select [(value)]="selectedCauserInternal">
      <mat-option *ngFor="let causer of causersInternal | async" [value]="causer">
        {{ causer.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Verursacher (extern)</mat-label>
    <mat-select [(value)]="selectedCauserExternal">
      <mat-option *ngFor="let causer of causersExternal | async" [value]="causer">
        {{ causer.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field style="grid-column: auto / span 2">
    <mat-label>Beschreibung</mat-label>
    <textarea matInput type="text" [(ngModel)]="description"></textarea>
  </mat-form-field>

  <div style="grid-column: auto / span 2">
    <pw-file-input [multiple]="true" (filesChanged)="onFileSelected($event)"></pw-file-input>
  </div>

  <div style="display: flex; align-items: end; justify-content: end; grid-column: auto / span 2">
    <button mat-raised-button (click)="createIncident()" color="accent">ANLEGEN</button>
  </div>
</div>
