import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {CoreService, ProleisObject} from "proleis-rest-client";
import {Observable} from "rxjs";

@Component({
  selector: 'p4m-qr-code',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.scss']
})
export class QrCodeComponent implements OnInit {

  object$: Observable<ProleisObject>

  constructor(public activatedRoute: ActivatedRoute, private cs: CoreService) {
  }

  ngOnInit() {
    this.object$ = this.cs.getObjectById(this.activatedRoute.snapshot.params.objectId);
  }

  protected readonly Number = Number;
  protected readonly String = String;
}
