import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {DashboardItemOptionsComponent, DashboardService} from 'proleis-web-dashboard';
import {ProleisObject} from 'proleis-rest-client';

@Component({
  selector: 'p4m-dashboard-capacity-heatmap-options',
  templateUrl: 'capacity.heatmap.options.component.html'
})
export class CapacityHeatmapOptionsComponent implements OnInit {

  @ViewChild(DashboardItemOptionsComponent, {static: false}) itemOptions: DashboardItemOptionsComponent;

  selectedResources: ProleisObject[];

  includeVP: boolean;
  includePR: boolean;
  onlyActive: boolean;
  excludeFinished: boolean;

  resTypes: string[] = [
    'b40ef99a-295d-11b2-80bb-e344dc12dd97',
    'da37a758-27fe-11b2-80f0-f32fdb3f5985',
    'da37a4e2-27fe-11b2-80f0-f32fdb3f5985'
  ];

  constructor(private bottomSheetRef: MatBottomSheetRef, @Inject(MAT_BOTTOM_SHEET_DATA) public data, private ds: DashboardService) {
  }

  ngOnInit(): void {
  }

  dismiss() {
    this.bottomSheetRef.dismiss();
  }

  update() {
    this.ds.editDashboardItem(this.data.item.dashboardName, {
      itemSize: this.itemOptions.selectedItemSize,
      itemName: this.itemOptions.selectedName,
      dashboardName: this.data.item.dashboardName,
      componentTypeName: this.itemOptions.selectedComponentType,
      options: {
        onlyActive: this.onlyActive,
        includeVP: this.includeVP,
        includePR: this.includePR,
        excludeFinished: this.excludeFinished
      }
    });
  }

  onSelectionChanged(selection: ProleisObject[]) {
    this.selectedResources = selection;
  }
}
