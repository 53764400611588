import {Injectable, NgZone} from '@angular/core';
import {Router} from '@angular/router';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {MatSnackBar, MatSnackBarRef} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private error$: Subject<Error> = new BehaviorSubject(null);

  constructor(private snackBar: MatSnackBar, private zone: NgZone, private router: Router) {

  }

  showError(error: Error) {
    this.zone.run(() => {
      this.error$.next(error);

      const snackbBarRef: MatSnackBarRef<any> = this.snackBar.open(error.message, 'Details', {
        duration: 3000,
        verticalPosition: 'bottom'
      });

      snackbBarRef.onAction().subscribe(() => {
        this.router.navigate(['/error']);
      });
    });
  }

  showNotification(msg: string) {
    this.zone.run(() => {
      const snackBarRef: MatSnackBarRef<any> = this.snackBar.open(msg, 'Ok', {
        duration: 3000,
        verticalPosition: 'bottom'
      });
    });
  }

  get error(): Observable<Error> {
    return this.error$.asObservable();
  }

}
