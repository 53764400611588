import {ProleisObject, Task} from 'proleis-rest-client';
import {createEntityAdapter, EntityState} from '@ngrx/entity';

export interface DashboardState {
  mdeInfo: MdeInfoState;
  favorites: ProleisObject[];
  tasks: Task[];
  userResources: ProleisObject[];
  heatmapData: ProleisObject[];
  incidents: ProleisObject[];
  deliveryInfo: DeliveryInfoState;
  capacityData: CapacityDataState;
}

export interface DeliveryInfo {
  pse: ProleisObject;
  afoCountPlanned?: number;
  afoSumPlanned?: number;
  afoSumWorkingtime?: number;
  afoSumOvertime?: number;
  afoPercentCountUnfinishedFinished?: number;
  afoCountFinished?: number;
  afoPercentPlannedTime?: number;
  afoSumPlannedTime?: number;
  afoPercentWorkingtime?: number;
  countAll: number;
  countRequested: number;
  countOrdered: number;
  countDelivered: number;
  countAssemblyReady: number;
  percentOrdered: number;
  percentDelivered: number;
  percentAssemblyReady: number;
}

export interface DeliveryInfoState extends EntityState<DeliveryInfo> {

}

export const deliveryInfoAdapter = createEntityAdapter<DeliveryInfo>({
  selectId: model => model.pse.OBJECT_ID
});

export interface CapacityData {
  res: string;
  capacityData: ProleisObject[];
}

export interface CapacityDataState extends EntityState<CapacityData> {

}

export const capacityDataAdapter = createEntityAdapter<CapacityData>({
  selectId: model => model.res
});

export interface MdeInfo {
  machine: ProleisObject;
  machineData: ProleisObject[];
}

export interface MdeInfoState extends EntityState<MdeInfo> {

}

export const mdeInfoAdapter = createEntityAdapter<MdeInfo>({
  selectId: model => model.machine.OBJECT_ID
});
