import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {metaReducers, reducers} from './store.index';
import {UiStateModule} from './ui/ui.state.module';
import {QualityStateModule} from './quality/quality.state.module';
import {LogisticsStateModule} from './logistics/logistics.state.module';
import {EffectsModule} from '@ngrx/effects';
import {GeneralStateModule} from './general/general.state.module';
import {DashboardStateModule} from './dashboard/dashboard.state.module';
import {PlanningStateModule} from './planning/planning.state.module';

@NgModule({
  imports: [
    StoreModule.forRoot(reducers, {metaReducers}),
    StoreDevtoolsModule.instrument({}),
    EffectsModule.forRoot([]),
    UiStateModule,
    QualityStateModule,
    LogisticsStateModule,
    GeneralStateModule,
    DashboardStateModule,
    PlanningStateModule
  ]
})
export class ProleisForMobileStoreModule {

}
