import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StoreModule} from '@ngrx/store';
import {dashboardFeatureKey} from './dashboard.index';
import {dashboardReducer} from './dashboard.reducer';
import {EffectsModule} from '@ngrx/effects';
import {DashboardEffects} from './dashboard.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(dashboardFeatureKey, dashboardReducer),
    EffectsModule.forFeature([DashboardEffects])
  ]
})
export class DashboardStateModule {

}
