import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {ProleisForMobileState} from '../store.index';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {CoreService, Param, PlanningService, ProleisObject, Task, Types, UserService} from 'proleis-rest-client';
import {PlanningActions} from './planning.index';
import {concatMap, exhaustMap, map, mergeMap, switchMap, tap} from 'rxjs/operators';
import {CurrentViewService, NotificationService} from 'proleis-web-app';
import {forkJoin} from 'rxjs';
import {Location} from '@angular/common';
import {GUID_DIR_WERTUNGSARTEN, GUID_OBJECT_PLAN_ROOT, GUID_PATH_PLAN, GUID_PATH_VIEW} from "../../../p4m/guids";

@Injectable()
export class PlanningEffects {
  constructor(private store: Store<ProleisForMobileState>, private actions: Actions,
              private cs: CoreService, private us: UserService, private cvs: CurrentViewService,
              private ps: PlanningService, private ns: NotificationService, private location: Location) {

  }

  loadResources$ = createEffect(() => {
    return this.actions.pipe(
      ofType(PlanningActions.loadPpsResources),
      exhaustMap(action => {
        return this.us.getUserResources().pipe(
          map(resources => PlanningActions.onPpsResourcesLoaded({resources}))
        );
      })
    );
  });

  onResourcesLoaded$ = createEffect(() => {
    return this.actions.pipe(
      ofType(PlanningActions.onPpsResourcesLoaded),
      tap(action => this.cvs.setLoading(false))
    );
  }, {
    dispatch: false
  });

  loadPpsResourceInfo$ = createEffect(() => {
    const aliasFieldsTask = 'AFOARTNAME=AFO_ART_ID.NAME;ORDERNAME=PARENT.AUFTRAG_ID.TEXT;POSTEXT=LINK_ID.TEXT;' +
      'POSID=LINK_ID;PARTNAME=LINK_ID.ROOT_ID.PARENT[TYP_ID.GUID=b514dea4-27fa-11b2-800a-b390cd5bbe84].TEXT;' +
      'PROJECTNAME=LINK_ID.ROOT_ID.PARENT[TYP_ID.GUID=b514d7ec-27fa-11b2-800a-b390cd5bbe84].TEXT' +
      ';LINKROOT=LINK_ID.ROOT_ID;LINKHEAD=LINK_ID.HEAD_ID;ROOTNAME=LINK_ID.ROOT_ID.NAME;' +
      'ROOTTEXT=LINK_ID.ROOT_ID.TEXT;INFOTEXT=OBJECT_ID.INFOTEXT';
    return this.actions.pipe(
      ofType(PlanningActions.loadPpsResourceInfoState),
      concatMap(action => {
        this.cvs.setLoading(true);
        return this.cs.getObjectByGuid<ProleisObject>(GUID_PATH_PLAN).pipe(
          exhaustMap(planPath => {
            return this.cs.getChildren<Task[]>(action.resId, planPath.OBJECT_ID, aliasFieldsTask).pipe(
              map(tasks => PlanningActions.onPpsResourceInfoStateLoaded({
                resourceInfo: {
                  resId: action.resId,
                  tasks
                }
              }))
            );
          })
        );
      })
    );
  });

  onPpsResourceInfoLoaded$ = createEffect(() => {
    return this.actions.pipe(
      ofType(PlanningActions.onPpsResourceInfoStateLoaded)
    );
  }, {
    dispatch: false
  });

  setTaskInProgress$ = createEffect(() => {
    return this.actions.pipe(
      ofType(PlanningActions.setTaskInProgress),
      exhaustMap(action => {
        this.cvs.setLoading(true);
        return forkJoin({
            task: this.cs.getObjectById<Task>(action.taskId),
            result: this.cs.executeAction(action.taskId, '§69697338-28ff-11b2-8057-2735d8eeb821', null)
          }
        ).pipe(
          map((task, result) => PlanningActions.onSetTaskInProgress({resId: action.resId}))
        );
      })
    );
  });

  onSetTaskInProgess = createEffect(() => {
    return this.actions.pipe(
      ofType(PlanningActions.onSetTaskInProgress),
      map(action => PlanningActions.loadPpsResourceInfoState({
        resId: action.resId
      }))
    );
  });

  setTaskComplete$ = createEffect(() => {
    return this.actions.pipe(
      ofType(PlanningActions.setTaskComplete),
      exhaustMap(action => {
        this.cvs.setLoading(true);
        return this.ps.setTaskComplete(action.taskId).pipe(
          map(result => PlanningActions.onSetTaskComplete({resId: action.resId}))
        );
      })
    );
  });

  onSetTaskComplete$ = createEffect(() => {
    return this.actions.pipe(
      ofType(PlanningActions.onSetTaskComplete),
      map(action => PlanningActions.loadPpsResourceInfoState({resId: action.resId}))
    );
  });

  setTaskInterrupted$ = createEffect(() => {
    return this.actions.pipe(
      ofType(PlanningActions.setTaskInterrupted),
      exhaustMap(action => {
        this.cvs.setLoading(true);
        return this.cs.executeAction(action.taskId, '§0bf69513-2ad7-11b2-8010-032c4d2fc27a').pipe(
          map(result => PlanningActions.onSetTaskComplete({
            resId: action.resId
          }))
        );
      })
    );
  });

  onSetTaskInterrupted$ = createEffect(() => {
    return this.actions.pipe(
      ofType(PlanningActions.onSetTaskInterrupted),
      map(action => PlanningActions.loadPpsResourceInfoState({
        resId: action.resId
      }))
    );
  });

  setFilter$ = createEffect(() => {
    return this.actions.pipe(
      ofType(PlanningActions.setPpsFilter),
      exhaustMap(action => {
        const res = action.resId;
        const params: Param[] = [];
        if (action.start) {
          params.push({name: 'FILTER_GEQ_STARTPLAN', value: action.start, type: Types.DATE});
        }
        if (action.end) {
          params.push({name: 'FILTER_LEQ_ENDEPLAN', value: action.end, type: Types.DATE});
        }
        if (action.lead) {
          params.push({name: 'FILTER_LEQ_PLANVORLAUF', value: action.lead, type: Types.DOUBLE});
        }
        if (action.includeFinished) {
          params.push({name: 'FILTER_EQ_ISTBEARBEITET', value: action.includeFinished, type: Types.BOOL});
        }
        if (action.onlyReleased) {
          params.push({name: 'FILTER_GEQ_FREIGABE_STATUS', value: action.onlyReleased ? 100 : 0, type: Types.DOUBLE});
        }
        return this.cs.getObjectByGuid<ProleisObject>(GUID_OBJECT_PLAN_ROOT).pipe(
          exhaustMap(planRoot => {
            return this.cs.updateObject(planRoot.OBJECT_ID, params).pipe(
              switchMap(result => [
                PlanningActions.onPpsFilterSet()
              ])
            );
          })
        );
      })
    );
  });

  loadFilter$ = createEffect(() => {
    return this.actions.pipe(
      ofType(PlanningActions.loadPpsFilter),
      exhaustMap(action => {
        const aliasFields = 'START=FILTER_LEQ_ENDEPLAN;END=FILTER_LEQ_ENDEPLAN;' +
          'LEAD=FILTER_LEQ_PLANVORLAUF;INCLUDE_FINISHED=FILTER_EQ_ISTBEARBEITET;ONLY_RELEASED=FILTER_GEQ_FREIGABE_STATUS;'
          + 'RANGE_START=OBJECT_ID.CALC_RANGE_STARTDATE;RANGE_END=OBJECT_ID.CALC_RANGE_ENDDATE';
        this.cvs.setLoading(true);
        return this.cs.getObjectByGuid<ProleisObject>(GUID_OBJECT_PLAN_ROOT, aliasFields).pipe(
          map(planRoot => PlanningActions.onPpsFilterLoaded({
            start: new Date(planRoot.FILTER_GEQ_STARTPLAN),
            end: new Date(planRoot.FILTER_LEQ_ENDEPLAN),
            lead: planRoot.LEAD,
            onlyReleased: planRoot.ONLY_RELEASED,
            includeFinished: planRoot.INCLUDE_FINISHED,
            rangeEnd: planRoot.RANGE_END,
            rangeStart: planRoot.RANGE_START
          }))
        );
      })
    );
  });

  onFilterLoaded$ = createEffect(() => {
    return this.actions.pipe(
      ofType(PlanningActions.onPpsFilterLoaded),
      tap(action => this.cvs.setLoading(false))
    );
  }, {dispatch: false});

  createTimeTracking$ = createEffect(() => {
    return this.actions.pipe(
      ofType(PlanningActions.createTimeTracking),
      mergeMap(action => {
        return this.us.createTimeTracking(action.body, action.startObjectId).pipe(
          map(result => PlanningActions.onTimeTrackingCreated({result}))
        );
      })
    );
  });

  onTimeTrackingCreated$ = createEffect(() => {
    return this.actions.pipe(
      ofType(PlanningActions.onTimeTrackingCreated),
      tap(action => this.cvs.setLoading(false)),
      tap(action => this.ns.showNotification('Buchung angelegt')),
      tap(action => this.location.back())
    );
  }, {dispatch: false});


  loadValuationTypes$ = createEffect(() => {
    return this.actions.pipe(
      ofType(PlanningActions.loadEvaluationTypes),
      exhaustMap(action => {
        return forkJoin({
          valDir: this.cs.getObjectByGuid<ProleisObject>(GUID_DIR_WERTUNGSARTEN),
          viewPath: this.cs.getObjectByGuid<ProleisObject>(GUID_PATH_VIEW)
        }).pipe(
          exhaustMap(value => {
            return this.cs.getChildren<ProleisObject[]>(value.valDir.OBJECT_ID, value.viewPath.OBJECT_ID).pipe(
              map(valTypes => PlanningActions.onEvaluationTypesLoaded({
                evaluationTypes: valTypes
              }))
            );
          })
        );
      })
    );
  });

  loadUserBookings$ = createEffect(() => {
    return this.actions.pipe(
      ofType(PlanningActions.loadUserBookings),
      exhaustMap(action => {
        this.cvs.setLoading(true);
        return this.us.getUserTimeTracking(action.start, action.end,
          action.position, action.aliasFields, action.fields).pipe(
          map(bookings => PlanningActions.onUserBookingsLoaded({bookings}))
        );
      })
    );
  });

  onUserBookingsLoaded$ = createEffect(() => {
    return this.actions.pipe(
      ofType(PlanningActions.onUserBookingsLoaded),
      tap(action => this.cvs.setLoading(false))
    );
  }, {dispatch: false});

  loadBookingMetadata$ = createEffect(() => {
    return this.actions.pipe(
      ofType(PlanningActions.loadBookingMetaData),
      exhaustMap(action => {
        return this.cs.getObjectById<ProleisObject>(action.objectId).pipe(
          exhaustMap(task => {
            return forkJoin({
              booking: this.us.getUserTimeTracking(),
              task: this.cs.getObjectById<ProleisObject>(task.OBJECT_ID),
              evaluationType: this.cs.getObjectById<ProleisObject>(task.TAETIGKEIT_ID),
              costCentre: this.cs.getObjectById<ProleisObject>(task.KOSTENSTELLE_ID),
              resource: this.cs.getObjectById<ProleisObject>(task.AFO_RES_ID),
              pse: this.cs.getObjectById<ProleisObject>(task.WERKZEUG),
              position: this.cs.getObjectById<ProleisObject>(task.POSITION_ID)
            }).pipe(
              map(result => {
                return PlanningActions.onBookingMetaDataLoaded({
                  metadata: {
                    booking: result.booking[0],
                    evaluationType: result.evaluationType,
                    resource: result.resource,
                    task: result.task,
                    position: result.position,
                    pse: result.pse
                  }
                });
              })
            );
          })
        );
      })
    );
  });

  startTimeTracking$ = createEffect(() => {
    return this.actions.pipe(
      ofType(PlanningActions.startTimeTracking),
      exhaustMap(action => {
        return this.us.startTimeTracking(action.taskId).pipe(
          map(result => PlanningActions.onTimeTrackingStarted())
        );
      })
    );
  });

  onTimeTrackingStarted$ = createEffect(() => {
      return this.actions.pipe(
        ofType(PlanningActions.onTimeTrackingStarted),
        tap(result => this.ns.showNotification('Buchung gestartet'))
      );
    }, {
      dispatch: false
    }
  );

  stopTimeTracking$ = createEffect(() => {
    return this.actions.pipe(
      ofType(PlanningActions.stopTimeTracking),
      exhaustMap(action => {
        return this.us.stopTimeTracking(action.taskId).pipe(
          map(result => PlanningActions.onTimeTrackingStopped())
        );
      })
    );
  });

  onTimeTrackingStopped$ = createEffect(() => {
    return this.actions.pipe(
      ofType(PlanningActions.onTimeTrackingStopped),
      tap(result => this.ns.showNotification('Buchung gestoppt'))
    );
  }, {
    dispatch: false
  });

  loadActiveBooking$ = createEffect(() => {
    return this.actions.pipe(
      ofType(PlanningActions.loadActiveBooking),
      exhaustMap(action => {
        return this.us.getCurrentBookings().pipe(
          map(result => PlanningActions.onActiveBookingLoaded({activeBookings: result}))
        );
      })
    );
  });

}
