import {Injectable} from "@angular/core";
import {exhaustMap, Observable} from "rxjs";
import {
  P4mCategory,
  P4mCauser,
  P4mIncident,
  P4mIncidentStatus,
  P4mPriority,
  P4mReferenceObject,
  P4mResponsible
} from "./incident.models";
import {P4mIncidentsService} from "./p4mIncidentsService";
import {CoreService, IncidentService, ProleisObject, RequestParam} from "proleis-rest-client";
import {map} from "rxjs/operators";
import {CreateIncidentBody} from "proleis-rest-client/lib/models/request-dtos/create-incident.body";
import {GUID_PATH_VIEW} from "../../../../p4m/guids";

@Injectable({
  providedIn: 'root'
})
export class IncidentsRestProleisService extends P4mIncidentsService {

  private readonly query = 'getIncidentPicklist';

  constructor(private cs: CoreService, private is: IncidentService) {
    super();
  }

  createIncident(referenceId: string, incident: P4mIncident, files?: File[]): Observable<P4mIncident> {
    const body: CreateIncidentBody = {
      name: incident.name,
      referenceId,
      description: incident.description,
      categoryId: incident.mainCategory,
      priorityId: incident.priority,
      causerExternalId: incident.externalCauser,
      subCategoryId: incident.subCategory,
      causerInternalId: incident.internalCauser
    };
    return this.is.createIncident(body, files).pipe(
      map(incident => {
        return {
          name: incident.name,
          priority: incident.priorityId,
          internalCauser: incident.internalCauserId,
          subCategory: incident.subCategoryId,
          id: incident.id,
          externalCauser: incident.externalCauserId,
          mainCategory: incident.mainCategoryId,
          description: incident.description
        }
      })
    );
  }


  deleteIncident(incidentId: string): Observable<any> {
    return null;
  }

  loadCategories(): Observable<P4mCategory[]> {
    const param: RequestParam = {
      "ELEMENT": "HAUPTKATEGORIE_ID"
    };
    return this.cs.getObjectsQuery<ProleisObject[]>(this.query, param).pipe(
      map(categories => categories.map(c => {
        return {
          name: c.TEXT,
          id: c.OBJECT_ID
        } as P4mCategory
      }))
    );
  }

  loadSubcategories(categoryId: string): Observable<P4mCategory[]> {
    const param: RequestParam = {
      "ELEMENT": "KATEGORIE_ID",
      "MAINCATEGORY_ID": categoryId
    };
    return this.cs.getObjectsQuery<ProleisObject[]>(this.query, param).pipe(
      map(categories => categories.map(c => {
        return {
          name: c.TEXT,
          id: c.OBJECT_ID
        } as P4mCategory
      }))
    );
  }

  loadPriorities(): Observable<P4mPriority[]> {
    const param: RequestParam = {
      "ELEMENT": "PRIO"
    };
    return this.cs.getObjectsQuery<ProleisObject[]>(this.query, param).pipe(
      map(categories => categories.map(c => {
        return {
          name: c.TEXT,
          id: c.OBJECT_ID
        } as P4mPriority
      }))
    );
  }

  loadCausersInternal(): Observable<P4mCauser[]> {
    const param: RequestParam = {
      "ELEMENT": "AUSLOESER_ID"
    };
    return this.cs.getObjectsQuery<ProleisObject[]>(this.query, param).pipe(
      map(categories => categories.map(c => {
        return {
          name: c.TEXT,
          id: c.OBJECT_ID
        } as P4mCauser
      }))
    );
  }

  loadCausersExternal(): Observable<P4mCauser[]> {
    const param: RequestParam = {
      "ELEMENT": "FIRMA_ID"
    };
    return this.cs.getObjectsQuery<ProleisObject[]>(this.query, param).pipe(
      map(categories => categories.map(c => {
        return {
          name: c.TEXT,
          id: c.OBJECT_ID
        } as P4mCauser
      }))
    );
  }

  loadReferenceObject(objectId: string): Observable<P4mReferenceObject> {
    return this.cs.getObjectById(objectId);
  }

  getIncidents(start?: Date, end?: Date, linkId?: string, categoryId?: string, statusId?: string,
               description?: string, mode?: string, field?: string): Observable<P4mIncident[]> {
    return this.is.getIncidents(start, end, categoryId, statusId, linkId, description, mode, field).pipe(
      map(incidents => {
        return incidents.map(inc => {
          return {
            name: inc.name,
            description: inc.description,
            mainCategory: inc.mainCategoryId,
            externalCauser: inc.externalCauserId,
            id: inc.id,
            subCategory: inc.subCategoryId,
            internalCauser: inc.internalCauserId,
            priority: inc.priorityId
          }
        })
      })
    );
  }

  loadStatus(): Observable<P4mIncidentStatus[]> {
    return this.cs.getObjectByGuid<ProleisObject>('944f1e70-29f1-11b2-80dc-b9b16226d298').pipe(
      exhaustMap(statusDir => this.cs.getChildren<ProleisObject[]>(statusDir.OBJECT_ID, '§' + GUID_PATH_VIEW).pipe(
          map(status => {
            return status.map(stat => {
              return {
                name: stat.NAME,
                id: stat.OBJECT_ID
              }
            })
          })
        )
      ));
  }

  loadResponsibles(incidentId: string): Observable<P4mResponsible[]> {
    return this.cs.getObjectById<ProleisObject>(incidentId).pipe(
      exhaustMap(incident => this.cs.getPicklist<ProleisObject[]>(incidentId, 'BEARBEITER_ID', '').pipe(
          map(users => {
            return users.map(user => {
              return {
                name: user.TEXT,
                id: user.OBJECT_ID
              }
            })
          })
        )
      )
    )
  }

}
