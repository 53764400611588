import {Injectable} from '@angular/core';
import {CurrentViewService} from 'proleis-web-app';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {setLoading, toggleSideDrawer} from './ui.actions';
import {tap} from 'rxjs/operators';

@Injectable()
export class UiEffects {
  constructor(private cvs: CurrentViewService, private actions: Actions) {
  }

  toggleSideDrawer$ = createEffect(
    () => {
      return this.actions.pipe(
        ofType(toggleSideDrawer),
        tap(() => this.cvs.drawer.toggle())
      );
    }, {dispatch: false}
  );

  toggleLoading$ = createEffect(
    () => {
      return this.actions.pipe(
        ofType(setLoading),
        tap((action) => this.cvs.setLoading(action.isLoading))
      );
    }, {dispatch: false}
  );

}

