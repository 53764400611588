import {HttpResponse} from '@angular/common/http';

export class FileResponse {
  content: Blob;
  fileName: string;
  fileSize?: number;
  fileLastMod?: Date;

  public static parseResponse(response: HttpResponse<Blob>): FileResponse {
    return {
      content: response.body,
      fileName: this.parseContentDisposition(response.headers.get('Content-Disposition')),
      fileSize: Number(response.headers.get('X-File-Size')),
      fileLastMod: new Date(response.headers.get('X-File-LastMod'))
    };
  }

  private static parseContentDisposition(contentDisposition: string): string {
    const temp = contentDisposition.split(';');
    let fileName = 'nofilename';
    temp.forEach(split => {
      split = split.trim();
      if (split.startsWith('filename="')) {
        fileName = split.substring(split.indexOf('"') + 1, split.lastIndexOf('"'));
      }
    });
    return fileName;
  }
}
