<mat-card *ngIf="machine">
  <mat-card-title>{{machine.NAME}}</mat-card-title>
  <mat-card-content>
    <highcharts-chart [Highcharts]="Highcharts" [oneToOne]="true" [options]="chartOptions"
                      *ngIf="data"></highcharts-chart>
    <div *ngIf="!data">Keine Daten für {{machine.NAME}} vorhanden</div>
  </mat-card-content>
  <mat-card-actions align="end">
    <div>
      <button *ngIf="ds.isDashboardEditable() | async" mat-button color="accent" (click)="openOptionsBottomSheet()">
        EDIT
      </button>
      <button mat-button color="primary">
        INFO
      </button>
      <button mat-button color="primary" *ngIf="ds.isDashboardEditable() | async" (click)="delete()">
        <mat-icon>remove</mat-icon>
      </button>
    </div>
  </mat-card-actions>
</mat-card>

<mat-card *ngIf="!item.options.machineId">
  <mat-card-title>MDE Statistiken</mat-card-title>
  <mat-card-content>
    Bitte
    <button mat-button color="accent" (click)="openOptionsBottomSheet()">Maschine auswählen!</button>
  </mat-card-content>
</mat-card>
