import {Component} from '@angular/core';
import {MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {RedirectService} from '../../../../general/services/redirect.service';

@Component({
  selector: 'p4m-scan-part',
  templateUrl: 'scan-part.component.html'
})
export class ScanPartComponent {

  constructor(private bsRef: MatBottomSheetRef) {
  }

  onCodeScanned(event) {
    this.bsRef.dismiss({
      partId: event
    });
  }
}
