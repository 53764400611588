<pw-view-title>
  Buchung anlegen
</pw-view-title>

<div class="mw-70">
  <mat-card *ngIf="form && (task$ | async) as task">

    <mat-card-header>
      <mat-card-title>{{task.TEXT}}</mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <form class="container" [formGroup]="form.get('base')" style="padding-top: 24px">
        <ng-container>
          <div *ngIf="task.RESMACHINEBOOKING"
               style="display: flex; flex-direction: column; flex-wrap: wrap; grid-column: auto / span 2">
            <mat-radio-group formControlName="machineTracking" (change)="onMachineTrackingChanged($event)">
              <mat-radio-button [checked]="!form.get('base.machineTracking').getRawValue()" value="false">Mitarbeiter
              </mat-radio-button>
              <mat-radio-button [checked]="form.get('base.machineTracking').getRawValue()" value="true">Maschine
              </mat-radio-button>
            </mat-radio-group>
          </div>

        </ng-container>

        <mat-form-field>
          <mat-label>Arbeit</mat-label>
          <mat-hint>3h 30m / 03:30 / 3.5</mat-hint>
          <input formControlName="workingTime" matInput>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Datum</mat-label>
          <input formControlName="date" matInput [matDatepicker]="picker">
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <mat-form-field style="grid-column: auto / span 2">
          <mat-label>Bemerkung</mat-label>
          <textarea formControlName="description" matInput></textarea>
        </mat-form-field>

        <mat-form-field *ngIf="(incidents$ | async)?.length > 0" style="grid-column: auto / span 2">
          <mat-label>Vorfall</mat-label>
          <mat-select formControlName="incidentId">
            <mat-option *ngFor="let inc of incidents$ | async" [value]="inc.OBJECT_ID">
              {{inc.TEXT}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="(task$ | async)?.RES_ID === 0">
          <mat-label>Ressource</mat-label>
          <mat-select formControlName="resId">
            <mat-option [value]="0"></mat-option>
            <mat-option *ngFor="let res of res$ | async" [value]="res.OBJECT_ID">
              {{res.TEXT}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </form>

      <p4m-additional-tracking [formGroup]="form.get('additional')"
                               [ratingTypes]="ratingTypes$ | async"
                               [serviceTypes]="serviceTypes$ | async"
                               [activities]="activities$ | async"
                               [positions]="positions$ | async" (ratingTypeChanged)="onRatingTypeChanged($event)">
      </p4m-additional-tracking>
    </mat-card-content>

    <mat-card-actions align="end" style="margin: 8px" *ngIf="form">
      <button mat-stroked-button color="accent" style="margin-right: 12px"
              (click)="createPreliminaryTracking()">
        BUCHUNG HINZUFÜGEN
      </button>
    </mat-card-actions>

  </mat-card>

  <mat-expansion-panel style="margin-top: 12px" expanded="true" *ngIf="multiTracking">
    <mat-expansion-panel-header>Vorläufige Buchungen</mat-expansion-panel-header>
    <p4m-multi-tracking-component #multiTrackingRef [trackingData]="getTrackingData()"
                                  (preliminaryTrackingsChanged)="onTrackingsChanged($event)">

    </p4m-multi-tracking-component>
  </mat-expansion-panel>

  <div class="p4m-flex-container" style="padding-top: 12px; align-items: end">
    <button mat-raised-button color="accent" [disabled]="!form.valid"
            (click)="createTrackings()">BUCHEN
    </button>
  </div>
</div>
