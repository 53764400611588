import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {MatTab, MatTabChangeEvent} from '@angular/material/tabs';
import {Router} from '@angular/router';
import {Resource} from 'proleis-rest-client';
import {enterLeave} from 'proleis-web-animations';
import {BreakpointService, CurrentViewService} from 'proleis-web-app';
import {debounceTime, map, Observable, startWith, tap} from 'rxjs';
import {ScanPartComponent} from '../overlays/scan-part.component';
import {PpsService} from './pps.service';
import {RedirectService} from '../../../../general/services/redirect.service';

@Component({
  selector: 'p4m-pps-board',
  templateUrl: 'pps.board.component.html',
  styleUrls: ['pps.board.component.scss'],
  animations: [enterLeave],
  encapsulation: ViewEncapsulation.None
})

export class PpsBoardComponent implements OnInit {

  searchFormControl: FormControl;

  filterStart: Date;
  filterEnd: Date;
  filterFinished: boolean;
  filterOnlyReleased: boolean;
  filterLead: number;
  filterStartRange: Date;
  filterEndRange: Date;

  isMobile: boolean;

  constructor(private cvs: CurrentViewService, private bottomSheet: MatBottomSheet, private router: Router,
              public ppsService: PpsService, private bs: BreakpointService, private rds: RedirectService) {
  }

  ngOnInit() {
    this.initializeTaskFilter();
    this.ppsService.loadResources();
    this.loadFilterSettings();
    this.bs.isSmall().subscribe(isSmall => {
      this.isMobile = isSmall;
    });
  }

  loadFilterSettings() {
    this.ppsService.loadPpsFilter().subscribe(filterSettings => {
      this.filterStartRange = filterSettings?.rangeStart;
      this.filterStart = filterSettings?.start;
      this.filterEndRange = filterSettings?.rangeEnd;
      this.filterEnd = filterSettings?.end;
      this.filterFinished = filterSettings?.includeFinished;
      this.filterOnlyReleased = filterSettings?.onlyReleased;
      this.filterLead = filterSettings?.lead;
    });
  }

  initializeTaskFilter() {
    this.searchFormControl = new FormControl<any>('');
    const filter$: Observable<string> = this.searchFormControl.valueChanges.pipe(
      tap(value => this.cvs.setLoading(true)),
      debounceTime(250),
      startWith('')
    );
    this.ppsService.setFilter(filter$);
  }

  onTabChanged(event: MatTabChangeEvent) {
    this.getTabRes(event.tab).subscribe(res => {
      this.ppsService.resourceChangedHandler(res);
    });
  }

  getTabRes(tab: MatTab): Observable<Resource> {
    const resName = tab.textLabel;
    return this.ppsService.resources$.pipe(
      map(resources => resources.filter(res => res.NAME === resName)[0])
    );
  }

  toggleDrawer() {
    this.cvs.drawer.toggle();
  }

  setFilter() {
    this.ppsService.setPpsFilter({
      end: this.filterEnd,
      includeFinished: this.filterFinished,
      onlyReleased: this.filterOnlyReleased,
      lead: this.filterLead,
      start: this.filterStart,
      rangeEnd: this.filterEndRange,
      rangeStart: this.filterStartRange
    });

    this.cvs.drawer.toggle();
  }

  cancel() {
    this.cvs.drawer.toggle();
  }

  openScanPartBottomSheet() {
    this.bottomSheet.open(ScanPartComponent).afterDismissed().subscribe(result => {
      if (result.partId) {
        this.ppsService.isTask(result.partId).subscribe(isTask => {
          if (isTask) {
            this.rds.redirectToInfoPanel(result.partId);
          } else {
            this.router.navigate(['/planning/' + result.partId + '/tasks']);
          }
        });
      }
    });
  }

  onResourceChanged(event: any) {
    localStorage.setItem('pps_selected_res', event.value.OBJECT_ID);
    this.ppsService.resourceChangedHandler(event.value);
  }
}
