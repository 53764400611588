<mat-list>
  <mat-list-item *ngFor="let doc of documents">
    <img matListItemIcon [src]="doc.ICON_URL"/>
    <span matListItemLine>{{ doc.TEXT }}</span>
    <div matListItemMeta style="display: flex; align-items: center">
      <span class="version-tag">{{ doc.VERSIONNR }}</span>
      <button mat-icon-button (click)="downloadDocument(doc.OBJECT_ID)">
        <mat-icon>download</mat-icon>
      </button>
    </div>
  </mat-list-item>
</mat-list>
