<p4m-expandable-card-component [titleClass]="getContainerClass()">
  <ng-container class="card-title">
    <div class="booking-header">
      <div style="font-weight: bold">{{ booking?.DATUM_BUCHUNG | date: 'shortDate' }}</div>
      <div style="font-weight: bold">{{ booking?.STUNDEN | number }}h</div>
      <div style="font-weight: bold">{{ booking?.PROJECT_NAME }}</div>
      <div style="font-weight: bold">{{ booking?.BEMINR }}</div>
      <div style="grid-column: auto / span 4; display: grid; grid-template-columns: auto 1fr; align-content: center">
        <div style="white-space: normal;  overflow: hidden;  text-overflow: ellipsis;">{{ booking?.AFOBEZ }}
        </div>
        <div class="tag-container">
          <div *ngIf="booking.STORNO_BUCHUNG_ID != 0"
               class="tag storno">{{ 'planning.booking.canceledTag' | translate }}
          </div>
          <div *ngIf="booking.IST_MANNLOS" class="tag machine">{{ 'planning.booking.machineTag' | translate }}</div>
          <div *ngIf="!booking.IST_MANNLOS" class="tag employee">{{ 'planning.booking.employeeTag' | translate }}</div>
        </div>
      </div>
    </div>

  </ng-container>

  <ng-container class="card-content">
    <div style="display: grid; grid-template-columns: auto 1fr; grid-gap: 8px; padding: 12px">
      <div *ngIf="isNotEmpty(booking?.AFO_DESCR)" style="font-weight: bold">{{ 'planning.booking.task' | translate }}
      </div>
      <a *ngIf="isNotEmpty(booking?.AFO_DESCR)" (click)="redirect(booking.OBJECT_ID)">{{ booking?.AFO_DESCR }}</a>

      <div *ngIf="isNotEmpty(booking?.POS_TEXT)"
           style="font-weight: bold">{{ 'planning.booking.position' | translate }}
      </div>
      <div *ngIf="isNotEmpty(booking?.POS_TEXT)">{{ booking?.POS_TEXT }}</div>

      <div *ngIf="isNotEmpty(booking?.AUFTRAG)" style="font-weight: bold">{{ 'planning.booking.order' | translate }}
      </div>
      <div *ngIf="isNotEmpty(booking?.AUFTRAG)">{{ booking?.AUFTRAG }}</div>

      <div *ngIf="isNotEmpty(booking?.SERVICE_TYPE_NAME)"
           style="font-weight: bold">{{ 'planning.booking.serviceType' | translate }}
      </div>
      <div *ngIf="isNotEmpty(booking?.SERVICE_TYPE_NAME)">{{ booking?.SERVICE_TYPE_NAME }}</div>

      <div *ngIf="isNotEmpty(booking?.TAETIGKEIT_NAME)"
           style="font-weight: bold">{{ 'planning.booking.ratingType' | translate }}
      </div>
      <div *ngIf="isNotEmpty(booking?.TAETIGKEIT_NAME)">{{ booking?.TAETIGKEIT_NAME }}</div>

      <div *ngIf="isNotEmpty(booking?.INCIDENT_NAME)"
           style="font-weight: bold">{{ 'planning.booking.incident' | translate }}
      </div>
      <div *ngIf="isNotEmpty(booking?.INCIDENT_NAME)">{{ booking?.INCIDENT_NAME }}</div>

      <div *ngIf="isNotEmpty(booking?.BEMERKUNG)"
           style="font-weight: bold">{{ 'planning.booking.position' | translate }}
      </div>
      <div *ngIf="isNotEmpty(booking?.BEMERKUNG)">{{ booking?.BEMERKUNG }}</div>

      <div *ngIf="isNotEmpty(booking?.PROJECT_NAME)"
           style="font-weight: bold">{{ 'planning.booking.project' | translate }}
      </div>
      <div *ngIf="isNotEmpty(booking?.PROJECT_NAME)">{{ booking?.PROJECT_NAME }}</div>

      <div *ngIf="isNotEmpty(booking?.BEMIBEZ)" style="font-weight: bold">{{ 'planning.booking.tool' | translate }}
      </div>
      <div *ngIf="isNotEmpty(booking?.BEMIBEZ)">{{ booking?.BEMIBEZ }}</div>

      <div *ngIf="isNotEmpty(booking?.RES_NAME)"
           style="font-weight: bold">{{ 'planning.booking.resource' | translate }}
      </div>
      <div *ngIf="isNotEmpty(booking?.RES_NAME)">{{ booking?.RES_NAME }}</div>
    </div>
  </ng-container>

  <ng-container class="card-actions">
    <button *ngIf="booking?.STORNO_BUCHUNG_ID === 0" mat-button color="accent"
            routerLink="/planning/bookings/{{booking?.BDE_BUCHUNG_ID}}/edit">
      {{ 'planning.booking.edit' | translate }}
    </button>
    <button *ngIf="booking?.STORNO_BUCHUNG_ID === 0" mat-button color="accent" (click)="storno()">
      {{ 'planning.booking.cancel' | translate }}
    </button>
  </ng-container>
</p4m-expandable-card-component>
