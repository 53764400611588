import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Store} from '@ngrx/store';
import {ProleisForMobileState} from '../../../../store/store.index';
import {GeneralActions} from '../../../../store/general/general.index';
import {PseViewModel} from '../../../../store/general/general.state';
import {selectPseViewModel} from '../../../../store/general/general.selectors';
import * as Highcharts from 'highcharts';
import {Options} from 'highcharts';
import {Milestone, Task} from 'proleis-rest-client';
import {MatPaginator, PageEvent} from '@angular/material/paginator';

@Component({
  selector: 'p4m-pse-infopanel',
  templateUrl: 'pse.info.panel.component.html',
  styleUrls: ['pse.info.panel.component.scss']
})
export class PseInfoPanelComponent implements OnInit, AfterViewInit {

  pseViewModel: PseViewModel;

  @ViewChild('taskPaginator', {static: false, read: MatPaginator}) taskPaginator: MatPaginator;
  displayedTasks: Task[];
  @ViewChild('msPaginator', {static: false, read: MatPaginator}) msPaginator: MatPaginator;
  displayedMs: Milestone[];
  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Options;

  constructor(private store: Store<ProleisForMobileState>, private activatedRoute: ActivatedRoute) {

  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(params => {
      const id = params.get('id');
      this.store.dispatch(GeneralActions.initializePseView({
        pseId: id
      }));

      this.store.select(selectPseViewModel).subscribe((pseViewModel: PseViewModel) => {
        this.pseViewModel = pseViewModel;
        if (pseViewModel) {
          this.buildChart();
        }
      });
    });
  }

  initPaginators() {
    this.taskPaginator.length = this.pseViewModel.tasks.length;
    this.taskPaginator.pageSize = 10;
    this.displayedTasks = this.pseViewModel.tasks.slice(0, 10);

    this.msPaginator.length = this.pseViewModel.milestones.length;
    this.msPaginator.pageSize = 10;
    this.displayedMs = this.pseViewModel.milestones.slice(0, 10);
  }

  ngAfterViewInit(): void {
    this.initPaginators();
  }

  buildChart() {
    this.chartOptions = {
      chart: {
        type: 'column'
      },
      title: {
        text: 'Übersicht VP/PR/IST'
      },
      xAxis: {
        categories: this.pseViewModel.workloadComparison.map(elem => elem.GROUPNAME),
        crosshair: true
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Arbeit (h)'
        }
      },
      series: [
        {
          type: 'column',
          name: 'VP',
          data: this.pseViewModel.workloadComparison.map(elem => Number(elem.VP_ARBEIT))
        },
        {
          type: 'column',
          name: 'PR',
          data: this.pseViewModel.workloadComparison.map(elem => Number(elem.PR_ARBEIT))
        },
        {
          type: 'column',
          name: 'Ist Mann',
          data: this.pseViewModel.workloadComparison.map(elem => Number(elem.IST_ARBEIT_PERS))
        },
        {
          type: 'column',
          name: 'Ist Maschine',
          data: this.pseViewModel.workloadComparison.map(elem => Number(elem.IST_ARBEIT_MANNLOS))
        }
      ]
    };

    console.log(this.chartOptions);
  }

  onTaskPaginatorEvent(event: PageEvent) {
    const sliceStart = event.pageIndex * event.pageSize;
    const sliceEnd = sliceStart + event.pageSize;
    this.displayedTasks = this.pseViewModel.tasks.slice(sliceStart, sliceEnd);
  }

  onVisible(event) {
    console.log(event);
  }
}
