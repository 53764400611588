import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {catchError, retry} from 'rxjs/operators';
import {NotificationService} from './notification.service';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {LoadingService} from 'proleis-web-app';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(private errorService: NotificationService, private router: Router, private ls: LoadingService) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.errorService.showError(new Error('Session abgelaufen, bitte erneut anmelden'));
          this.ls.setLoading(false, req.url);
          this.router.navigate(['/index']).then();
        } else if (error.status === 404) {
          return next.handle(req);
        } else {
          const err = JSON.parse(error.error);
          this.errorService.showNotification(err?.message);
          return throwError({
            name: err.status,
            message: err.message
          });
        }
      })
    );
  }

}
