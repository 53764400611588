import {Component, Input, OnInit} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'p4m-expandable-card-component',
  templateUrl: 'expandable-card.component.html',
  styleUrls: ['expandable-card.component.scss'],
  animations: [
    trigger('expansionTrigger', [
      state('expanded', style({
        'max-height': '100vh'
      })),
      state('collapsed', style({
        'max-height': 0
      })),
      transition('expanded => collapsed', [
        animate('500ms ease-in-out')
      ]),
      transition('collapsed => expanded', [
        animate('500ms ease-in-out')
      ])
    ])
  ]
})
export class ExpandableCardComponent implements OnInit {

  expanded = false;

  @Input()
  titleClass: string;

  constructor() {
  }

  ngOnInit() {
  }

  toggleExpanded() {
    this.expanded = !this.expanded;
    console.log(this.expanded);
  }


}
