import {ProleisObject, Resource, Task} from 'proleis-rest-client';
import {createEntityAdapter, EntityState} from '@ngrx/entity';

export interface PlanningState {
  ppsResources: Resource[];
  ppsState: PpsResourceState;
  ppsFilter: PpsFilter;
  evaluationTypes: ProleisObject[];
  userBookings: ProleisObject[];
  activeBookings: ProleisObject[];
  bookingMetadata: BookingMetadata;
}

export interface PpsResource {
  resId: string;
  tasks: Task[];
}

export interface PpsResourceState extends EntityState<PpsResource> {

}

export const ppsResourceAdapter = createEntityAdapter<PpsResource>({
  selectId: model => model.resId
});

export interface PpsFilter {
  start: Date;
  end: Date;
  lead: number;
  includeFinished: boolean;
  onlyReleased: boolean;
  rangeStart: Date;
  rangeEnd: Date;
}

export interface BookingMetadata {
  booking: ProleisObject;
  task: ProleisObject;
  resource: ProleisObject;
  position: ProleisObject;
  pse: ProleisObject;
  evaluationType: ProleisObject;
}
