import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StoreModule} from '@ngrx/store';
import {generalFeatureKey, generalReducer} from './general.reducer';
import {EffectsModule} from '@ngrx/effects';
import {GeneralEffects} from './general.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(generalFeatureKey, generalReducer),
    EffectsModule.forFeature([GeneralEffects])
  ]
})
export class GeneralStateModule {

}
