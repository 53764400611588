<div class="background" *ngIf="!(clientAuth.isAuthenticated$ | async); else app">
  <pw-login class="background login-wrapper"></pw-login>
</div>

<ng-template #app>
  <pw-app-layout #layout [layoutMode]="layoutMode" theme="tebis-light-theme">
    <pw-menu-container>
      <pw-sidenav *ngIf="layoutMode==='sales' && sidenavData" [navData]="sidenavData" logo="./assets/tebis_logo.svg"
                  logoLink="/home">
        <pw-sidenav-portal>
          <mat-divider></mat-divider>
          <div *ngIf="layoutMode === 'sales'"
               style="justify-content: center; align-items: center; display: flex; flex-direction: row">
            <button mat-button style="color: white" (click)="logout()">
              <mat-icon>vpn_key</mat-icon>
            </button>
          </div>
        </pw-sidenav-portal>
      </pw-sidenav>
      <p4m-app-menu *ngIf="layoutMode === 'default'" [layout]="layout"></p4m-app-menu>
    </pw-menu-container>
  </pw-app-layout>
</ng-template>

