<pw-view-title>{{ viewModel?.task?.NAME }}</pw-view-title>

<pw-view-actions>
  <button mat-button [matMenuTriggerFor]="menu">
    <mat-icon>more_vert</mat-icon>
  </button>
</pw-view-actions>

<mat-menu #menu="matMenu">
  <button mat-menu-item routerLink="/incidents/{{viewModel?.task?.OBJECT_ID}}/create">
    <span>{{ 'general.infoPanels.task.createIncident' | translate }}</span>
  </button>
  <button mat-menu-item routerLink="/general/info-panels/task/{{viewModel?.task?.OBJECT_ID}}/track">
    <span>{{ 'general.infoPanels.task.createTracking' | translate }}</span>
  </button>
  <button mat-menu-item (click)="ppsService.setTaskInProgress(viewModel?.task?.OBJECT_ID)">
    <span>{{ 'general.infoPanels.task.setInProgress' | translate }}</span>
  </button>
  <button mat-menu-item (click)="ppsService.setTaskComplete(viewModel?.task?.OBJECT_ID)">
    <span>{{ 'general.infoPanels.task.setFinished' | translate }}</span>
  </button>
  <button mat-menu-item routerLink="/general/info-panels/vc/{{viewModel?.taskContainer?.OBJECT_ID}}">
    <span>{{ 'general.infoPanels.task.showAllTasks' | translate }}</span>
  </button>
  <button mat-menu-item routerLink="/general/qr-code/{{viewModel?.task?.OBJECT_ID}}">
    <span>QR-Code</span>
  </button>

</mat-menu>

<div class="p4m-grid-4-col">

  <mat-card>
    <mat-card-header>
      <mat-card-title>
        Allgemein
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div style="display: grid; grid-template-columns: auto 1fr; grid-gap: 8px">
        <div style="font-weight: bold">Projekt</div>
        <div>{{ viewModel?.task?.PROJEKTNR }}</div>
        <div style="font-weight: bold">Teil</div>
        <div>{{ viewModel?.task?.TEILENR }} {{viewModel?.task?.TEILEBEZ}}</div>
        <div style="font-weight: bold">PSE</div>
        <a (click)="redirect(viewModel?.task?.BEMI_OBJ_ID)">{{ viewModel?.task?.BEMINR }}</a>
        <div style="font-weight: bold">Position</div>
        <a (click)="redirect(viewModel?.task?.BEMIPOS_OBJ_ID)">{{ viewModel?.task?.BEMIPOS }}</a>

      </div>
    </mat-card-content>

  </mat-card>

  <mat-card *ngIf="viewModel?.task">
    <mat-card-header>
      <mat-card-title>{{ 'general.infoPanels.task.plannedValues' | translate }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div style="display: grid; grid-template-columns: auto 1fr; grid-gap: 8px">
        <div style="font-weight: bold">{{ 'general.infoPanels.task.task' | translate }}</div>
        <div>{{ viewModel.task.TEXT }}</div>

        <div style="font-weight: bold">{{ 'general.infoPanels.task.type' | translate }}</div>
        <div>{{ viewModel.task.AFO_ART_TEXT }}</div>

        <div style="font-weight: bold">{{ 'general.infoPanels.task.work' | translate }}</div>
        <div>{{ viewModel.task.ARBEIT | duration }}</div>

        <div style="font-weight: bold">{{ 'general.infoPanels.task.plannedStart' | translate }}</div>
        <div>{{ viewModel.task.STARTPLAN | date: 'short' }}</div>

        <div style="font-weight: bold">{{ 'general.infoPanels.task.plannedEnd' | translate }}</div>
        <div>{{ viewModel.task.ENDEPLAN | date: 'short' }}</div>

        <div style="font-weight: bold">{{ 'general.infoPanels.task.resource' | translate }}</div>
        <div>{{ viewModel.resource.TEXT }}</div>

        <div style="font-weight: bold">{{ 'general.infoPanels.task.resourceGroup' | translate }}</div>
        <div>{{ viewModel.resourceGroup.TEXT }}</div>

        <div style="font-weight: bold">{{ 'general.infoPanels.task.taskContainer' | translate }}</div>
        <a routerLink="/general/info-panels/vc/{{viewModel.task.OBJECT_ID}}">{{ viewModel.taskContainer.TEXT }}</a>
      </div>

    </mat-card-content>
  </mat-card>

  <mat-card *ngIf="viewModel?.task">
    <mat-card-header>
      <mat-card-title>{{ 'general.infoPanels.task.status' | translate }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>

      <div style="display: grid; grid-template-columns: auto 1fr; grid-gap: 8px">
        <div style="font-weight: bold">{{ 'general.infoPanels.task.actualWork' | translate }}</div>
        <div>
          <div>{{ viewModel.task.ARBEITIST | duration }}</div>
          <mat-progress-bar
            [value]="viewModel.task.ARBEITIST / viewModel.task.ARBEIT_KORR * 100"></mat-progress-bar>
        </div>

        <div style="font-weight: bold">{{ 'general.infoPanels.task.remainingWork' | translate }}</div>
        <div>{{ viewModel.task.REMAININGWORK | duration }}</div>

        <div style="font-weight: bold">{{ 'general.infoPanels.task.correctedWork' | translate }}</div>
        <div>{{ viewModel.task.ARBEIT_KORR | duration }}</div>
      </div>

      <mat-divider style="margin-top: 8px"></mat-divider>

      <div>
        <mat-checkbox class="example-margin" [(ngModel)]="viewModel.task.ISTBEARBEITET"
                      disabled="true">{{ 'general.infoPanels.task.processed' | translate }}
        </mat-checkbox>
      </div>
      <div>
        <mat-checkbox class="example-margin" [(ngModel)]="viewModel.task.ISTFIXIERT"
                      disabled="true">{{ 'general.infoPanels.task.fixed' | translate }}
        </mat-checkbox>
      </div>
      <div>
        <mat-checkbox class="example-margin" [(ngModel)]="viewModel.task.ISTGEPLANT"
                      disabled="true">{{ 'general.infoPanels.task.planned' | translate }}
        </mat-checkbox>
      </div>
      <div>
        <mat-checkbox class="example-margin" [(ngModel)]="viewModel.task.ISTINARBEIT"
                      disabled="true">{{ 'general.infoPanels.task.inProgress' | translate }}
        </mat-checkbox>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card *ngIf="viewModel?.predecessors && viewModel?.successors">
    <mat-card-header>
      <mat-card-title>{{ 'general.infoPanels.task.predecessors' | translate }} / {{ 'general.infoPanels.task.successors' | translate }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <mat-tab-group>
        <mat-tab label="{{ 'general.infoPanels.task.predecessors' | translate }}">
          <mat-nav-list>
            <mat-list-item *ngFor="let pre of viewModel.predecessors" (click)="redirect(pre.OBJECT_ID)">
              <img matListItemAvatar [src]="pre.ICON_URL">
              <span matListItemLine style="word-wrap: anywhere; white-space: break-spaces">{{ pre.TEXT }}</span>
            </mat-list-item>
          </mat-nav-list>
        </mat-tab>
        <mat-tab label="{{ 'general.infoPanels.task.successors' | translate }}">
          <mat-nav-list>
            <mat-list-item *ngFor="let succ of viewModel.successors" (click)="redirect(succ.OBJECT_ID)">
              <img matListItemAvatar [src]="succ.ICON_URL">
              <span matListItemLine style="word-wrap: anywhere; white-space: break-spaces">{{ succ.TEXT }}</span>
            </mat-list-item>
          </mat-nav-list>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
  </mat-card>

  <mat-card *ngIf="viewModel?.activities">
    <mat-card-header>
      <mat-card-title>{{ 'general.infoPanels.task.activities' | translate }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <mat-nav-list *ngIf="viewModel.activities.length > 0">
        <mat-list-item *ngFor="let act of viewModel.activities">
          <img matListItemAvatar [src]="act.ICON_URL">
          <span matListItemLine>{{ act.TEXT }}</span>
        </mat-list-item>
      </mat-nav-list>
      <div *ngIf="viewModel.activities.length === 0">
        {{ 'general.infoPanels.task.noActivities' | translate }}
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card *ngIf="viewModel?.clampings">
    <mat-card-header>
      <mat-card-title>Aufspannungen</mat-card-title>
    </mat-card-header>
    <mat-card-content *ngIf="viewModel?.clampings?.length > 0; else noClampings">
      <mat-nav-list>
        <mat-list-item *ngFor="let clamping of viewModel.clampings" (click)="redirect(clamping.OBJECT_ID)">
          <span matListItemLine>{{clamping.TEXT}}</span>
        </mat-list-item>
      </mat-nav-list>
    </mat-card-content>
    <ng-template #noClampings>
      <mat-card-content>
        Keine Aufspannungen vorhanden
      </mat-card-content>
    </ng-template>
  </mat-card>


</div>
