import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Store} from '@ngrx/store';
import {ProleisForMobileState} from '../../../../store/store.index';
import {PlanningActions} from '../../../../store/planning/planning.index';
import {selectBookingMetaData} from '../../../../store/planning/planning.selectors';
import {BookingMetadata} from '../../../../store/planning/planning.state';
import {CoreService} from 'proleis-rest-client';

@Component({
  selector: 'p4m-booking-info-panel',
  templateUrl: 'booking.info.panel.component.html'
})

export class BookingInfoPanelComponent implements OnInit {

  bookingData: BookingMetadata;

  constructor(private route: ActivatedRoute, private store: Store<ProleisForMobileState>, private coreService: CoreService) {
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.store.dispatch(PlanningActions.loadBookingMetaData({objectId: params.objectId, bookingId: params.bookingId}));
    });

    this.store.select(selectBookingMetaData).subscribe(data => {
      this.bookingData = data;
    });
  }

}
