import {createAction, props} from '@ngrx/store';
import {ProleisObject} from 'proleis-rest-client';

export const scanObject = createAction(
  '[Scanner] scan object',
  props<{ scanCode: string }>()
);

export const onScanObjectSuccess = createAction(
  '[Scanner] scan object success',
  props<{ object: ProleisObject }>()
);

export const onScanSequenceSuccess = createAction(
  '[Scanner] scan sequence executed',
  props<{ result: any }>()
);

export const onScanError = createAction(
  '[Scanner] error',
  props<{ error: any }>()
);

export const relocate = createAction(
  '[Logistics] logistics relocation',
  props<{
    sourceId: string;
    targetId: string;
  }>()
);

export const onRelocateSuccess = createAction(
  '[Logistics] relocation successful'
);
