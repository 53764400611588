<pw-view-title>{{ viewModel?.position?.TEXT }}</pw-view-title>

<div class="p4m-grid-3-col">

  <div #projectInfo>
    <mat-card *ngIf="viewModel">
      <mat-card-header>
        <mat-card-title>Projektinformationen</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-nav-list>
          <mat-list-item *ngIf="viewModel.customer">
            <img matListItemIcon [src]="viewModel.customer?.ICON_URL"/>
            <span matListItemLine>{{ viewModel.customer?.TEXT }}</span>
          </mat-list-item>
          <mat-list-item *ngIf="viewModel.project">
            <img matListItemIcon [src]="viewModel.project?.ICON_URL"/>
            <span matListItemLine>{{ viewModel.project?.TEXT }}</span>
          </mat-list-item>
          <mat-list-item *ngIf="viewModel.part">
            <img matListItemIcon [src]="viewModel.part?.ICON_URL"/>
            <span matListItemLine>{{ viewModel.part?.TEXT }}</span>
          </mat-list-item>
          <mat-list-item routerLink="/general/info-panels/pse/{{viewModel.rootPse.OBJECT_ID}}">
            <img matListItemIcon [src]="viewModel.rootPse?.ICON_URL"/>
            <span matListItemLine>{{ viewModel.rootPse?.TEXT }}</span>
          </mat-list-item>
        </mat-nav-list>
      </mat-card-content>
    </mat-card>
  </div>

  <div #positions>
    <mat-card *ngIf="viewModel?.positions">

      <mat-card-header>
        <mat-card-title>Stückliste</mat-card-title>
      </mat-card-header>

      <mat-card-content>

        <mat-nav-list *ngIf="viewModel.positions.length > 0">
          <mat-list-item *ngFor="let pos of viewModel.positions"
                         routerLink="/general/info-panels/position/{{pos.OBJECT_ID}}">
            <img matListItemIcon src="{{pos.ICON_URL}}">
            <span matListItemLine>{{ pos.TEXT }}</span>
          </mat-list-item>
        </mat-nav-list>

        <div *ngIf="viewModel.positions.length === 0">
          Keine weiteren Positionen vorhanden.
        </div>

      </mat-card-content>

    </mat-card>
  </div>

  <div #tasks>
    <mat-card *ngIf="viewModel?.tasks">

      <mat-card-header>
        <mat-card-title>Vorgänge</mat-card-title>
      </mat-card-header>

      <mat-card-content *ngIf="viewModel?.tasks.length > 0">

        <mat-nav-list>
          <mat-list-item *ngFor="let task of viewModel.tasks"
                         routerLink="/general/info-panels/task/{{task.OBJECT_ID}}">
            <img matListItemIcon src="{{task.ICON_URL}}">
            <span matListItemLine>{{ task.TEXT }}</span>
          </mat-list-item>
        </mat-nav-list>

      </mat-card-content>

      <mat-card-content *ngIf="viewModel?.tasks.length === 0">
        Keine Vorgänge vorhanden.
      </mat-card-content>

    </mat-card>
  </div>


  <div #documents>
    <mat-card *ngIf="viewModel?.documents">
      <mat-card-header>
        <mat-card-title>Dokumente</mat-card-title>
      </mat-card-header>
      <mat-card-content *ngIf="viewModel.documents.length > 0">
        <app-document-list [documents]="viewModel?.documents"></app-document-list>
      </mat-card-content>
      <mat-card-content *ngIf="viewModel.documents.length === 0">
        Keine Dokumente vorhanden.
      </mat-card-content>
    </mat-card>
  </div>

  <mat-card *ngIf="viewModel?.clampings">
    <mat-card-header>
      <mat-card-title>Aufspannungen</mat-card-title>
    </mat-card-header>
    <mat-card-content *ngIf="viewModel?.clampings?.length > 0; else noClampings">
      <mat-nav-list>
        <mat-list-item *ngFor="let clamping of viewModel.clampings" (click)="redirect(clamping.OBJECT_ID)">
          <span matListItemLine>{{clamping.TEXT}}</span>
        </mat-list-item>
      </mat-nav-list>
    </mat-card-content>
    <ng-template #noClampings>
      <mat-card-content>
        Keine Aufspannungen vorhanden
      </mat-card-content>
    </ng-template>
  </mat-card>

</div>
