import {DashboardItemComponent, DashboardService} from 'proleis-web-dashboard';
import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {Store} from '@ngrx/store';
import {ProleisForMobileState} from '../../../../store/store.index';
import {DashboardActions} from '../../../../store/dashboard/dashboard.index';
import {Task} from 'proleis-rest-client';
import {selectDashboardTasks} from '../../../../store/dashboard/dashboard.selectors';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {DashboardTaskOptionsComponent} from './dashboard.task.options.component';

@Component({
  selector: 'p4m-dashboard-tasks',
  templateUrl: 'dashboard.task.component.html'
})
export class DashboardTaskComponent extends DashboardItemComponent implements OnInit, AfterViewInit {

  @ViewChild('taskPaginator', {static: false, read: MatPaginator}) taskPaginator: MatPaginator;
  tasks: Task[];
  displayedTasks: Task[];

  constructor(private store: Store<ProleisForMobileState>, private bottomSheet: MatBottomSheet, public ds: DashboardService) {
    super();
  }

  ngOnInit(): void {
    this.store.dispatch(DashboardActions.loadTasks({
      resId: this.item.options.resId
    }));
    this.store.select(selectDashboardTasks).subscribe(tasks => {
      this.tasks = tasks;
      this.displayedTasks = this.tasks.slice(0, 5);
    });
  }

  ngAfterViewInit(): void {

  }

  onTaskPaginatorEvent(event: PageEvent) {
    const sliceStart = event.pageIndex * event.pageSize;
    const sliceEnd = sliceStart + event.pageSize;
    this.displayedTasks = this.tasks.slice(sliceStart, sliceEnd);
  }

  openOptionsBottomSheet() {
    this.bottomSheet.open(DashboardTaskOptionsComponent, {
      data: {
        item: this.item,
        dashboard: this.item.dashboardName
      }
    });
  }

  delete() {
    this.ds.removeDashboardItem(this.item.dashboardName, this.item);
  }

}
