
import {ActionReducer, ActionReducerMap, MetaReducer} from '@ngrx/store';
import {environment} from '../../../environments/environment';

export interface ProleisForMobileState {

}

export const reducers: ActionReducerMap<ProleisForMobileState> = {

};

export const metaReducers: MetaReducer<ProleisForMobileState>[] = !environment.production
  ? [logger]
  : [];

export function logger(reducer: ActionReducer<ProleisForMobileState>): ActionReducer<ProleisForMobileState> {
  return (state: ProleisForMobileState, action: any): ProleisForMobileState => {
    console.log('state', state);
    console.log('action', action);

    return reducer(state, action);
  };
}
