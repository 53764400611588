import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable} from 'rxjs';
import {Task} from 'proleis-rest-client';

@Component({
  selector: 'p4m-pps-lane',
  templateUrl: 'lane.component.html'
})
export class LaneComponent implements OnInit {

  @Input() laneTitle: string;
  @Input() tasks: Observable<Task[]>;

  @Output() filterEvent: EventEmitter<string> = new EventEmitter<string>();

  fireFilterEvent() {
    this.filterEvent.emit('');
  }

  ngOnInit() {
  }
}
