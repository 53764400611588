<pw-view-title>{{ viewModel?.object?.NAME }}</pw-view-title>

<pw-view-actions>
  <mat-menu #menu>
    <button mat-menu-item routerLink="/incidents/{{viewModel?.object?.OBJECT_ID}}/create">
      <span>Vorfall anlegen</span>
    </button>
    <button mat-menu-item routerLink="/general/qr-code/{{viewModel?.object?.OBJECT_ID}}">
      <span>QR-Code</span>
    </button>
  </mat-menu>
  <button mat-icon-button [matMenuTriggerFor]="menu">
    <mat-icon>more_vert</mat-icon>
  </button>

</pw-view-actions>

<div class="p4m-grid-4-col" *ngIf="viewModel">

  <mat-card>
    <mat-card-header>
      <mat-card-title>{{ 'general.infoPanels.rootPos.productionStatus' | translate }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div style="display: grid; grid-template-columns: auto 1fr; grid-gap: 8px">
        <div style="font-weight: bold">{{ 'general.infoPanels.rootPos.countTasks' | translate }}</div>
        <div>{{ viewModel?.deliveryInfo?.afoCountPlanned }}</div>
        <div style="font-weight: bold">{{ 'general.infoPanels.rootPos.planned' | translate }}</div>
        <div>{{ viewModel?.deliveryInfo?.afoSumPlannedTime }}</div>
        <div style="font-weight: bold">{{ 'general.infoPanels.rootPos.actual' | translate }}</div>
        <div>{{ viewModel?.deliveryInfo?.afoSumWorkingtime }}</div>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-header>
      <mat-card-title>
        {{ 'general.infoPanels.rootPos.deliveryInformation' | translate }}
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div style="display: grid; grid-template-columns: auto 1fr; grid-gap: 8px">

        <div style="font-weight: bold;">{{ 'general.infoPanels.rootPos.orderDate' | translate }}</div>
        <div>{{ viewModel?.deliveryInfo?.pse?.DWS_BESTELLDATUM | date }}</div>

        <div style="font-weight: bold;">{{ 'general.infoPanels.rootPos.deliveryDate' | translate }}</div>
        <div>{{ viewModel?.deliveryInfo?.pse?.DWS_LIEFERDATUM | date }}</div>

        <div style="font-weight: bold;">{{ 'general.infoPanels.rootPos.ordered' | translate }}
        </div>
        <div>
          {{ viewModel?.deliveryInfo?.countOrdered }}
          / {{ viewModel?.deliveryInfo?.countAll }}
          <mat-progress-bar value="{{viewModel.deliveryInfo.percentOrdered}}"></mat-progress-bar>
        </div>

        <div style="font-weight: bold;">{{ 'general.infoPanels.rootPos.delivered' | translate }}</div>
        <div>
          <div>{{ viewModel?.deliveryInfo?.countDelivered }}
            / {{ viewModel?.deliveryInfo?.countAll }}
          </div>
          <mat-progress-bar value="{{viewModel.deliveryInfo.percentDelivered}}"></mat-progress-bar>
        </div>

        <div style="font-weight: bold;">{{ 'general.infoPanels.rootPos.assemblyReady' | translate }}
        </div>
        <div>
          {{ viewModel?.deliveryInfo?.countAssemblyReady }}
          / {{ viewModel?.deliveryInfo?.countAll }}
          <mat-progress-bar value="{{viewModel?.deliveryInfo?.percentAssemblyReady}}"></mat-progress-bar>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-header>
      <mat-card-title>{{ 'general.infoPanels.rootPos.orders' | translate }}</mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <mat-nav-list>
        <mat-list-item *ngFor="let order of viewModel.orders"
                       routerLink="/general/info-panels/order/{{order.OBJECT_ID}}">
          <span matListItemLine>{{ order.NAME }}</span>
          <span matListItemLine>{{ order.DESCRIPTION }}</span>
        </mat-list-item>
      </mat-nav-list>
    </mat-card-content>
  </mat-card>

  <!--
  <div #report>
    <mat-card *ngIf="chartOptions">
      <mat-card-header>
        <mat-card-title>Übersicht Stunden</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <highcharts-chart [Highcharts]="Highcharts" [constructorType]="" [oneToOne]="true"
                          [options]="chartOptions"></highcharts-chart>
      </mat-card-content>
    </mat-card>
  </div>
  -->

  <mat-card *ngIf="viewModel?.milestones">

    <mat-card-header>
      <mat-card-title-group>
        <mat-card-title>{{ 'general.infoPanels.rootPos.milestones' | translate }}</mat-card-title>
        <div>
          <button mat-icon-button>
            <mat-icon>sort</mat-icon>
          </button>
          <button mat-icon-button>
            <mat-icon>search</mat-icon>
          </button>
        </div>
      </mat-card-title-group>
    </mat-card-header>

    <mat-card-content *ngIf="viewModel?.milestones">
      <mat-nav-list>
        <mat-list-item *ngFor="let ms of displayedMs" routerLink="/general/info-panels/ms/{{ms.OBJECT_ID}}">
          <ng-container matListItemIcon [ngSwitch]="getStatusIcon(ms)">
            <mat-icon *ngSwitchCase="'done'" color="primary">done</mat-icon>
            <mat-icon *ngSwitchCase="'schedule'" color="primary">schedule</mat-icon>
            <mat-icon *ngSwitchCase="'warning'" color="warn">warning</mat-icon>
          </ng-container>
          <span matListItemLine>{{ ms.TEXT }}</span>
          <span matListItemLine>{{ ms.MS_DATUM | date }}</span>
        </mat-list-item>
      </mat-nav-list>

      <mat-paginator #msPaginator [length]="filteredMs.length"
                     [pageSize]="5"
                     [pageSizeOptions]="[5, 10, 15]" (page)="onMsPagination($event)">
      </mat-paginator>

    </mat-card-content>

  </mat-card>

  <mat-card *ngIf="viewModel?.positionsAAA && viewModel?.positions">

    <mat-card-header>
      <mat-card-title>{{ 'general.infoPanels.rootPos.partsList' | translate }}</mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <mat-tab-group>
        <mat-tab label="CAD">
          <mat-nav-list>
            <mat-list-item *ngFor="let pos of viewModel.positions"
                           routerLink="/general/info-panels/position/{{pos.OBJECT_ID}}">
              <img matListItemIcon src="{{pos.ICON_URL}}"/>
              <span matListItemLine>{{ pos.TEXT }}</span>
            </mat-list-item>
          </mat-nav-list>
        </mat-tab>

        <mat-tab label="FP">
          <mat-nav-list>
            <mat-list-item *ngFor="let pos of viewModel.positionsAAA"
                           routerLink="/general/info-panels/cart/{{pos.OBJECT_ID}}">
              <img matListItemIcon src="{{pos.ICON_URL}}"/>
              <span matListItemLine>{{ pos.TEXT }}</span>
            </mat-list-item>
          </mat-nav-list>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
  </mat-card>

  <mat-card *ngIf="viewModel?.documentsOffice && viewModel?.documentsCax">
    <mat-card-header>
      <mat-card-title>{{ 'general.infoPanels.rootPos.documents' | translate }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <mat-tab-group>
        <mat-tab label="Office">
          <app-document-list [documents]="viewModel?.documentsOffice"></app-document-list>
        </mat-tab>
        <mat-tab label="CAx">
          <app-document-list [documents]="viewModel?.documentsCax"></app-document-list>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-header>
      <mat-card-title>{{ 'general.infoPanels.rootPos.contacts' | translate }}</mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <div class="info-form">
        <div>{{ 'general.infoPanels.rootPos.constructor' | translate }}</div>
        <div>{{ viewModel?.object?.KONSTRUKTEUR }}</div>
        <div>{{ 'general.infoPanels.rootPos.master' | translate }}</div>
        <div>{{ viewModel?.object?.MEISTER }}</div>
        <div>{{ 'general.infoPanels.rootPos.productionPlanner' | translate }}</div>
        <div>{{ viewModel?.object?.PLANER }}</div>
        <div>{{ 'general.infoPanels.rootPos.worker' | translate }}</div>
        <div>{{ viewModel?.object?.WERKER }}</div>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-header>
      <mat-card-title>{{ 'general.infoPanels.rootPos.storage' | translate }}</mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <div class="info-form">
        <div>{{ 'general.infoPanels.rootPos.storageLocation' | translate }}</div>
        <div>{{ viewModel?.object?.LAGERORT_NAME }}</div>
        <div>{{ 'general.infoPanels.rootPos.storageCompartment' | translate }}</div>
        <div>{{ viewModel?.object?.LAGERFACH_NAME }}</div>
      </div>
    </mat-card-content>
  </mat-card>


  <mat-card *ngIf="viewModel?.previewImages">
    <mat-card-header>
      <mat-card-title>Preview</mat-card-title>
    </mat-card-header>
    <mat-card-content *ngIf="previewFiles | async as previews">
      <pw-gallery *ngIf="previews.length > 0" #gallery noContentMsg="Keine Previews vorhanden" columns="auto auto"
                  [images]="previews"></pw-gallery>
    </mat-card-content>
    <mat-card-actions align="end">
      <button mat-button color="primary" (click)="switchViewMode()">
        <mat-icon>collections</mat-icon>
      </button>
    </mat-card-actions>
  </mat-card>


  <mat-card *ngIf="viewModel?.clampings">
    <mat-card-header>
      <mat-card-title>Aufspannungen</mat-card-title>
    </mat-card-header>
    <mat-card-content *ngIf="viewModel?.clampings?.length > 0; else noClampings">
      <mat-nav-list>
        <mat-list-item *ngFor="let clamping of viewModel.clampings" (click)="redirect(clamping.OBJECT_ID)">
          <span matListItemLine>{{ clamping.TEXT }}</span>
        </mat-list-item>
      </mat-nav-list>
    </mat-card-content>
    <ng-template #noClampings>
      <mat-card-content>
        Keine Aufspannungen vorhanden
      </mat-card-content>
    </ng-template>
  </mat-card>
</div>

