<ng-container *ngIf="vc$ | async as vc">

  <pw-view-title>
    <div class="title-max-width">{{vc.TEXT}}</div>
  </pw-view-title>

  <pw-view-actions>
    <button mat-button [matMenuTriggerFor]="menu">
      <mat-icon>more_vert</mat-icon>
    </button>
  </pw-view-actions>

  <mat-menu #menu="matMenu">
    <button mat-menu-item routerLink="/general/qr-code/{{vc?.OBJECT_ID}}">
      <span>QR-Code</span>
    </button>
  </mat-menu>


  <div class="mw-70">
    <mat-expansion-panel>
      <mat-expansion-panel-header>{{vc.TEXT}}</mat-expansion-panel-header>
      <div style="display: grid; grid-template-columns: auto 1fr; grid-gap: 12px">
        <div style="font-weight: bold">Name</div>
        <div>{{vc.NAME}}</div>
        <div style="font-weight: bold">Benennung</div>
        <div>{{vc.DESCRIPTION}}</div>
        <div style="font-weight: bold">Art</div>
        <div>{{vc.CONTAINER_NAME}}</div>
      </div>
    </mat-expansion-panel>

    <mat-form-field appearance="fill" style="width: 100%; margin-top: 12px">
      <mat-label>Filter</mat-label>
      <input type="text" matInput placeholder="Filter" [formControl]="searchFormControl">
    </mat-form-field>

    <div style="margin-top: 12px" class="mat-elevation-z6">
      <ng-container *ngIf="tasks$ | async as tasks; else noTasks">
        <ng-container *ngFor="let task of tasks;index as i">
          <p4m-mobile-task-component [task]="task"></p4m-mobile-task-component>
          <mat-divider *ngIf="i < tasks.length" vertical="false"></mat-divider>
        </ng-container>

      </ng-container>
      <ng-template #noTasks>
        <h3>Keine Vorgänge vorhanden</h3>
      </ng-template>
    </div>
  </div>
</ng-container>
