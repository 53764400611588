import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {CoreService, Task} from 'proleis-rest-client';
import {CreateIncidentComponent} from '../../../quality/components/create-incident/create.incident.component';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {Store} from '@ngrx/store';
import {ProleisForMobileState} from '../../../store/store.index';
import {RedirectService} from '../../services/redirect.service';
import {MatDialog} from '@angular/material/dialog';
import {AppConfigService} from 'proleis-web-app';
import {PpsService} from '../../../planning/components/pps/board/pps.service';

@Component({
  selector: 'p4m-mobile-task-component',
  templateUrl: 'mobile-task.component.html',
  styleUrls: ['mobile-task.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MobileTaskComponent implements OnInit {

  allowStartTracking = false;
  allowSetUnapprovedTasksInProgress = false;

  @Input() task: Task;
  @Output() onStartTracking = new EventEmitter<Task>;

  constructor(private bottomSheet: MatBottomSheet, private store: Store<ProleisForMobileState>, private redirectService: RedirectService,
              private dialog: MatDialog, private cs: CoreService, private configService: AppConfigService, public ppsService: PpsService) {
  }

  ngOnInit() {
    const config: any = this.configService.getConfig();
    this.allowStartTracking = config.general.allowStartTracking;
    this.allowSetUnapprovedTasksInProgress = config.general.allowSetUnapprovedTasksInProgress;
  }

  getTaskStatusClass(task: Task): string {
    if (task.ISTBEARBEITET) {
      return 'task-finished';
    } else if (task.ISTINARBEIT && !task.ISTBEARBEITET) {
      return 'task-in-progress';
    } else if (task.FREIGABE_STATUS === 0) {
      return 'task-todo-red';
    } else if (task.FREIGABE_STATUS < 100) {
      return 'task-todo-yellow';
    } else {
      return 'task-todo-green';
    }
  }

  getProgress() {
    return this.task.ARBEITIST / this.task.ARBEITPLAN * 100;
  }

  onRedirect(task: Task) {
    this.redirectService.redirectToInfoPanel(task.OBJECT_ID);
  }

  onRedirectTool(task: Task) {
    this.redirectService.redirectToInfoPanel(task.LINKROOT);
  }

  onRedirectPos(task: Task) {
    this.redirectService.redirectToInfoPanel(task.POSID);
  }

  startTracking(task: Task) {
    this.onStartTracking.emit(task);
  }
}
