<mat-card *ngIf="(deliveryInfo$ | async) as deliveryInfo">
  <mat-card-header>
    <mat-card-title>
      {{'dashboard.widgets.deliveryInfo.deliveryStatus' | translate}} {{deliveryInfo.pse.NAME}}
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="p4m-grid-2-col">
      <span>{{'dashboard.widgets.deliveryInfo.ordered' | translate}}: </span>
      <mat-progress-bar value="{{deliveryInfo.percentOrdered}}"></mat-progress-bar>
      <span>{{'dashboard.widgets.deliveryInfo.delivered' | translate}}: </span>
      <mat-progress-bar value="{{deliveryInfo.percentDelivered}}"></mat-progress-bar>
      <span>{{'dashboard.widgets.deliveryInfo.readyForAssembly' | translate}}: </span>
      <mat-progress-bar value="{{deliveryInfo.percentAssemblyReady}}"></mat-progress-bar>
    </div>
  </mat-card-content>
  <mat-card-actions align="end">
    <button mat-button color="accent" *ngIf="ds.isDashboardEditable() | async" (click)="openOptionsBottomSheet()">
      EDIT
    </button>
    <button mat-button color="primary">
      INFO
    </button>
    <button mat-button color="primary" *ngIf="ds.isDashboardEditable() | async" (click)="delete()">
      <mat-icon>remove</mat-icon>
    </button>
  </mat-card-actions>
</mat-card>

<mat-card *ngIf="!item.options.pseId">
  <mat-card-title>{{'dashboard.widgets.deliveryInfo.deliveryStatus' | translate}}</mat-card-title>
  <mat-card-content>{{'dashboard.widgets.deliveryInfo.please' | translate}}
    <button mat-button color="accent"
            (click)="openOptionsBottomSheet()">{{'dashboard.widgets.deliveryInfo.selectElement' | translate}}!
    </button>
  </mat-card-content>
</mat-card>
