<div class="p4m-flex-container">

  <pw-dashboard-item-options [item]="data.item"></pw-dashboard-item-options>

  <pw-search [allowedTypes]="resTypes" [minCharacters]="2" [debounceTime]="250" [multiple]="true"
             [placeholder]="'dashboard.widgets.capacityHeatmap.options.searchPlaceholder' | translate"
             (selectionChanged)="onSelectionChanged($event)"></pw-search>

  <mat-checkbox class="example-margin"
                [(ngModel)]="onlyActive">{{'dashboard.widgets.capacityHeatmap.options.onlyActiveRessources' | translate}}
  </mat-checkbox>
  <mat-checkbox class="example-margin"
                [(ngModel)]="includePR">{{'dashboard.widgets.capacityHeatmap.options.prod' | translate}}
  </mat-checkbox>
  <mat-checkbox class="example-margin"
                [(ngModel)]="includeVP">{{'dashboard.widgets.capacityHeatmap.options.vp' | translate}}
  </mat-checkbox>
  <mat-checkbox class="example-margin"
                [(ngModel)]="excludeFinished">{{'dashboard.widgets.capacityHeatmap.options.excludeFinished' | translate}}
  </mat-checkbox>

  <div style="justify-self: end">
    <button mat-button color="primary" (click)="dismiss()">
      {{'dashboard.widgets.capacityHeatmap.options.discard' | translate}}
    </button>
    <button mat-raised-button color="primary" (click)="update()">
      {{'dashboard.widgets.capacityHeatmap.options.save' | translate}}
    </button>
  </div>
</div>
