import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {DashboardItemOptionsComponent, DashboardService} from 'proleis-web-dashboard';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {DashboardActions} from '../../../../store/dashboard/dashboard.index';
import {Store} from '@ngrx/store';
import {ProleisForMobileState} from '../../../../store/store.index';
import {selectDashboardUserResources} from '../../../../store/dashboard/dashboard.selectors';
import {Observable} from 'rxjs';
import {ProleisObject} from 'proleis-rest-client';

@Component({
  selector: 'p4m-dashboard-task-options',
  templateUrl: 'dashboard.task.options.component.html'
})
export class DashboardTaskOptionsComponent implements OnInit {

  @ViewChild(DashboardItemOptionsComponent, {static: false}) itemOptions: DashboardItemOptionsComponent;

  userResources$: Observable<ProleisObject[]>;
  selectedResource: ProleisObject;

  constructor(public ds: DashboardService, private bottomSheetRef: MatBottomSheetRef, @Inject(MAT_BOTTOM_SHEET_DATA) public data,
              private store: Store<ProleisForMobileState>) {
  }

  ngOnInit(): void {
    this.store.dispatch(DashboardActions.loadUserResources());
    this.userResources$ = this.store.select(selectDashboardUserResources);
  }

  dismiss() {
    this.bottomSheetRef.dismiss();
  }

  update() {
    this.ds.editDashboardItem(this.data.item.dashboardName, {
      itemSize: this.itemOptions.selectedItemSize,
      itemName: this.itemOptions.selectedName,
      dashboardName: this.data.item.dashboardName,
      componentTypeName: this.itemOptions.selectedComponentType,
      options: {
        resId: this.selectedResource.OBJECT_ID
      }
    });
  }



}
