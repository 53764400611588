export const highchartsColorPalette: string[] = [
  '#e57373',
  '#4dd0e1',
  '#81c784',
  '#ba68c8',
  '#4db6ac',
  '#dce775',
  '#64b5f6',
  '#ffd54f',
  '#7986cb'
];
