<pw-view-title>
  Home
</pw-view-title>

<div class="home-container mw-70">

  <h1 class="home-welcome span-3">Willkommen {{ (user$ | async)?.TEXT }}</h1>

  <div class="home-card mat-elevation-z6">
    <h2>Favoriten</h2>
    <mat-nav-list>
      <mat-list-item *ngFor="let fav of favorites$ | async" (click)="redirect(fav.OBJECT_ID)">
        <span>{{ fav.TEXT }}</span>
      </mat-list-item>
    </mat-nav-list>
  </div>

  <div class="home-card mat-elevation-z6 span-2" *ngIf="bookings$ | async as bookings">
    <h2>Letzte Buchungen</h2>
    <ng-container *ngFor="let booking of visibleBookings$ | async">
      <p4m-booking-component [booking]="booking"></p4m-booking-component>
      <mat-divider vertical="false"></mat-divider>
    </ng-container>
    <mat-paginator [pageSizeOptions]="[5, 10, 15]" pageSize="5" pageIndex="0" [length]="bookings.length"
                   (page)="bookingPageEvent($event)"></mat-paginator>
  </div>

  <div class="home-card mat-elevation-z6">
    <h2>Suche</h2>
    <pw-search style="width:100%" [maxResults]="5" [debounceTime]="250" [placeholder]="'Suche'"
               [multiple]="false" [minCharacters]="3" [allowedTypes]="allowedSearchTypes"
               (selectionChanged)="onSearchSelected($event)">
    </pw-search>
    <h3>Letzte Suchergebnisse:</h3>
    <mat-nav-list *ngIf="lastSearches">
      <mat-list-item *ngFor="let searchObj of lastSearches" (click)="redirect(searchObj)">
        <span>{{ searchObj?.TEXT }}</span>
      </mat-list-item>
    </mat-nav-list>
  </div>

  <div class="home-card mat-elevation-z6 span-2" *ngIf="tasks$ | async as tasks">
    <h2>Letzte bebuchte Vorgänge</h2>
    <ng-container *ngFor="let task of visibleTasks$ | async">
      <p4m-mobile-task-component *ngFor="let task of visibleTasks$ | async" [task]="task"></p4m-mobile-task-component>
      <mat-divider vertical="false"></mat-divider>
    </ng-container>
    <mat-paginator [pageSizeOptions]="[5, 10, 15]" pageSize="5" pageIndex="0" [length]="tasks.length"
                   (page)="taskPageEvent($event)"></mat-paginator>
  </div>

</div>


