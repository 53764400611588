import {Component, OnInit} from '@angular/core';
import {DashboardItemComponent, DashboardService} from 'proleis-web-dashboard';
import {Store} from '@ngrx/store';
import {ProleisForMobileState} from '../../../../store/store.index';
import {DashboardActions} from '../../../../store/dashboard/dashboard.index';
import {selectDashboardFavorites} from '../../../../store/dashboard/dashboard.selectors';
import {Observable} from 'rxjs';
import {ProleisObject} from 'proleis-rest-client';
import {RedirectService} from '../../../../general/services/redirect.service';

@Component({
  selector: 'p4m-dashboard-favorites',
  templateUrl: 'favorites.component.html'
})
export class FavoritesComponent extends DashboardItemComponent implements OnInit {

  favorites: Observable<ProleisObject[]>;

  constructor(private store: Store<ProleisForMobileState>, private redirectService: RedirectService, public ds: DashboardService) {
    super();
  }

  ngOnInit(): void {
    this.store.dispatch(DashboardActions.loadFavorites());
    this.favorites = this.store.select(selectDashboardFavorites);
  }

  onClick(fav: ProleisObject) {
    this.redirectService.redirectToInfoPanel(fav.OBJECT_ID);
  }

  delete() {
    this.ds.removeDashboardItem(this.item.dashboardName, this.item);
  }

}
