import {createFeatureSelector, createSelector} from '@ngrx/store';
import {planningFeatureKey} from './planning.index';
import {PlanningState} from './planning.state';

const planningFeatureSelector = createFeatureSelector<PlanningState>(planningFeatureKey);

export const selectPpsResources = createSelector(
  planningFeatureSelector,
  state => state.ppsResources
);

export const selectPpsResourceInfo = createSelector(
  planningFeatureSelector,
  (state, id) => state.ppsState.entities[id]
);

export const selectPpsFilter = createSelector(
  planningFeatureSelector,
  state => state.ppsFilter
);

export const selectEvaluationTypes = createSelector(
  planningFeatureSelector,
  state => state.evaluationTypes
);

export const selectUserBookings = createSelector(
  planningFeatureSelector,
  state => state.userBookings
);

export const selectBookingMetaData = createSelector(
  planningFeatureSelector,
  state => state.bookingMetadata
);

export const selectActiveBookings = createSelector(
  planningFeatureSelector,
  state => state.activeBookings
);
