import {createAction, props} from '@ngrx/store';

export const toggleSideDrawer = createAction(
  '[UI]: toggle side drawer'
);

export const setLoading = createAction(
  '[UI]: toggle loading indicator',
  props<{ isLoading: boolean }>()
);
