import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {CoreService, Param, ProleisObject, RequestParam, Types, UserService} from 'proleis-rest-client';
import {ActivatedRoute} from '@angular/router';
import {Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from '../../../../../../p4m/notification.service';

@Component({
  selector: 'app-edit-booking',
  templateUrl: './edit-booking.component.html',
  styleUrls: ['./edit-booking.component.scss']
})
export class EditBookingComponent implements OnInit {

  form: FormGroup;
  booking$: Observable<ProleisObject>;
  booking: ProleisObject;
  res$: Observable<ProleisObject[]>;
  activities$: Observable<ProleisObject[]>;
  positions$: Observable<ProleisObject[]>;
  incidents$: Observable<ProleisObject[]>;
  serviceTypes$: Observable<ProleisObject[]>;
  ratingTypes$: Observable<ProleisObject[]>;
  task: ProleisObject;


  constructor(private fb: FormBuilder, private cs: CoreService, private ar: ActivatedRoute, private us: UserService,
              private ns: NotificationService, private translate: TranslateService) {

  }

  ngOnInit() {
    this.loadData();
    this.buildForm();
  }

  buildForm() {
    this.booking$.subscribe(booking => {
      this.booking = booking;
      this.loadIncidents();
      this.loadPositions();
      this.loadActivities();
      this.loadServiceTypes();
      this.loadValuationTypes();
      this.form = this.fb.group({
        date: new FormControl(booking.DATUM_BUCHUNG),
        workingTime: new FormControl(booking.ARBEITIST),
        serviceTypeId: new FormControl(booking.SERVICE_TYPE_ID),
        ratingTypeId: new FormControl(booking.TAETIGKEIT_ID),
        incidentId: new FormControl(booking.VORFALL_ID),
        resId: new FormControl(booking.RES_ID),
        posId: new FormControl(booking.POS_ID),
        description: new FormControl(booking.BEMERKUNG)
      });
    });
  }

  loadData() {
    this.booking$ = this.us.getTimeTracking(this.ar.snapshot.params.id);

  }

  discard() {
    history.back();
  }

  save() {
    const params: Param[] = [{
      name: 'SERVICE_TYPE_ID',
      value: this.form.get('serviceTypeId').value,
      type: Types.DOUBLE
    }, {
      name: 'TAETIGKEIT_ID',
      value: this.form.get('ratingTypeId').value,
      type: Types.DOUBLE
    }, {
      name: 'DATUM_BUCHUNG',
      value: this.form.get('date').value,
      type: Types.DATE
    }, {
      name: 'STUNDEN',
      value: this.form.get('workingTime').value,
      type: Types.DOUBLE
    }, {
      name: 'BEMERKUNG',
      value: this.form.get('description').value,
      type: Types.TEXT
    }, {
      name: 'VORFALL_ID',
      value: this.form.get('incidentId').value,
      type: Types.DOUBLE
    }];

    this.cs.updateMetaData(this.booking.OBJECT_ID, 'BDE_BUCHUNG', this.booking.BDE_BUCHUNG_ID, params).subscribe(result => {
      this.ns.showNotification(this.translate.instant('planning.booking.editNotification'));
      history.back();
    });
  }

  private loadActivities() {
    const params: RequestParam = {
      TASK_ID: this.booking.OBJECT_ID,
      ELEMENT: 'OPERATION_OBJECT_ID',
      BOOKING_TYPE: this.booking.RESMACHINEBOOKING
    };
    this.activities$ = this.cs.getObjectsQuery<ProleisObject[]>('getBDEPicklist', params);
  }

  private loadPositions() {
    const params: RequestParam = {
      TASK_ID: this.booking.OBJECT_ID,
      ELEMENT: 'POSITION_OBJECT_ID',
      BOOKING_TYPE: this.booking.RESMACHINEBOOKING
    };
    this.positions$ = this.cs.getObjectsQuery<ProleisObject[]>('getBDEPicklist', params);
  }

  private loadIncidents() {
    const params: RequestParam = {
      TASK_ID: this.booking.OBJECT_ID,
      ELEMENT: 'INCIDENT_OBJECT_ID',
      BOOKING_TYPE: this.booking.RESMACHINEBOOKING
    };
    this.incidents$ = this.cs.getObjectsQuery<ProleisObject[]>('getBDEPicklist', params);
  }

  private loadServiceTypes() {
    const params: RequestParam = {
      TASK_ID: this.booking.OBJECT_ID,
      ELEMENT: 'SERVICE_TYPE_OBJECT_ID',
      BOOKING_TYPE: this.booking.RESMACHINEBOOKING
    };
    this.serviceTypes$ = this.cs.getObjectsQuery<ProleisObject[]>('getBDEPicklist', params);

  }

  private loadValuationTypes() {
    const params: RequestParam = {
      TASK_ID: this.booking.OBJECT_ID,
      ELEMENT: 'TASK_OBJECT_ID',
      BOOKING_TYPE: this.booking.RESMACHINEBOOKING
    };
    this.ratingTypes$ = this.cs.getObjectsQuery<ProleisObject[]>('getBDEPicklist', params);
  }
}
