import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {reducer, uiFeatureKey} from './ui.reducer';
import {EffectsModule} from '@ngrx/effects';
import {UiEffects} from './ui.effects';
import {CommonModule} from '@angular/common';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(uiFeatureKey, reducer),
    EffectsModule.forFeature([UiEffects])
  ]
})
export class UiStateModule {

}
