<div class="p4m-flex-container">

  <pw-dashboard-item-options [item]="data.item"></pw-dashboard-item-options>

  <mat-form-field>
    <mat-label>Ressource auswählen</mat-label>
    <mat-select [(ngModel)]="selectedResource">
      <mat-option *ngFor="let res of userResources$ | async" [value]="res">
        {{res.NAME}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div style="justify-self: end">
    <button mat-button color="primary" (click)="dismiss()">
      ABBRECHEN
    </button>
    <button mat-raised-button color="primary" (click)="update()">
      ÜBERNEHMEN
    </button>
  </div>
</div>
